import React from 'react';
import { Box, Typography, styled } from '@mui/material';

interface Game {
    id: string;
    awayTeamAbbreviation: string;
    homeTeamAbbreviation: string;
    startTime: string;
    slate_identifier: number;
}

interface GameProps {
    game: Game;
    selectedTeams: string[];
    onTeamToggle: (team: string) => void;
}

const GameBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '0.8rem',
}));

const TeamText = styled(Typography)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
    cursor: 'pointer',
    fontWeight: isSelected ? 'bold' : 'normal',
    color: isSelected ? theme.palette.primary.main : 'inherit',
    fontSize: '0.8rem',
}));

const GameDisplay: React.FC<GameProps> = React.memo(({ game, selectedTeams, onTeamToggle }) => {
    // console.log('GameDisplay rendering', game.id);

    const formatTime = React.useMemo(() => {
        return (timeString: string) => {
            const date = new Date(timeString);
            return date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
                timeZone: 'America/New_York'
            });
        };
    }, []);

    const handleHomeTeamToggle = React.useCallback(() => {
        onTeamToggle(game.homeTeamAbbreviation);
    }, [onTeamToggle, game.homeTeamAbbreviation]);

    const handleAwayTeamToggle = React.useCallback(() => {
        onTeamToggle(game.awayTeamAbbreviation);
    }, [onTeamToggle, game.awayTeamAbbreviation]);

    const isHomeSelected = React.useMemo(() => selectedTeams.includes(game.homeTeamAbbreviation), [selectedTeams, game.homeTeamAbbreviation]);
    const isAwaySelected = React.useMemo(() => selectedTeams.includes(game.awayTeamAbbreviation), [selectedTeams, game.awayTeamAbbreviation]);

    return (
        <GameBox>
            <TeamText
                isSelected={isHomeSelected}
                onClick={handleHomeTeamToggle}
            >
                {game.homeTeamAbbreviation}
            </TeamText>
            <TeamText
                isSelected={isAwaySelected}
                onClick={handleAwayTeamToggle}
            >
                {game.awayTeamAbbreviation}
            </TeamText>
            <Typography variant="caption">{formatTime(game.startTime)}</Typography>
        </GameBox>
    );
});

export default GameDisplay;