import React from 'react';
import { Box, Typography, } from '@mui/material';
import { useAuth } from '../context/AuthContext';

function Home() {
    const { isLoggedIn } = useAuth();



    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 64px)', // Adjust if your app bar height is different
            p: 4, // Increased padding for more spacing
            textAlign: 'center',
            // background: 'linear-gradient(180deg, #f3ec78, #af4261)', // Modern background gradient
            color: '#dc5f00', // White text for contrast
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            borderRadius: '12px' // Rounded corners for a smoother look
        }}>
            <Typography variant="h2" component="h1" sx={{
                fontWeight: 'bold',
                mb: 2, // Adds margin below the header 
                fontSize: '3rem', // Larger font for the title
            }}>
                Welcome to the Uncertain Edge DFS OS
            </Typography>
            {!isLoggedIn ? (
                <Typography variant="h5" sx={{
                    fontWeight: 300,
                    fontSize: '1.25rem'  // Slightly bigger and lighter font for subtext
                }}>
                    Please use the buttons in the top right corner to login or create an account.
                </Typography>
            ) : (
                <>

                    <Typography variant="h5" sx={{
                        fontWeight: 300,
                        fontSize: '1.25rem'  // Slightly bigger and lighter font for the second message
                    }}>
                        Choose a Sport, Date, and Slate from the menu in the header to get started.
                    </Typography>
                </>
            )
            }
        </Box >

    );
}

export default Home;