import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

interface UserSubscription {
    subscriptionTier: string;
    subscriptionEndDate: Date | null;
}

interface SubscriptionContextType {
    userSubscription: UserSubscription | null;
    isLoading: boolean;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useAuth();
    const [userSubscription, setUserSubscription] = useState<UserSubscription | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user?.uid) {
            const userDocRef = doc(db, 'users', user.uid);
            const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setUserSubscription({
                        subscriptionTier: data.subscriptionTier || 'free',
                        subscriptionEndDate: data.subscriptionEndDate ? data.subscriptionEndDate.toDate() : null,
                    });
                }
                setIsLoading(false);
            });

            return () => unsubscribe();
        } else {
            setUserSubscription(null);
            setIsLoading(false);
        }
    }, [user]);

    return (
        <SubscriptionContext.Provider value={{ userSubscription, isLoading }}>
            {children}
        </SubscriptionContext.Provider>
    );
};

export const useSubscription = () => {
    const context = useContext(SubscriptionContext);
    if (context === undefined) {
        throw new Error('useSubscription must be used within a SubscriptionProvider');
    }
    return context;
};