import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, Tab, Tabs, Typography, Paper, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayerPool from './LineupOptimizer/PlayerPool';
import Settings from './LineupOptimizer/Settings';
import Stacking from './LineupOptimizer/Stacking';
import ShowdownSettings from './ShowdownSettings';
import ShowdownStacking from './LineupOptimizer/ShowdownStacking';
import Grouping from './LineupOptimizer/Grouping';
import { SettingsProvider } from '../context/SettingsContext';
import { useSlateContext } from '../context/SlateContext';
import { PersistentStateProvider, usePersistentState } from '../context/PersistentStateContext';
import { Player } from '../types';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTab-root': {
        textTransform: 'none',
        minWidth: 72,
        fontSize: '1.1rem',
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(6),
        '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,
        },
        '&.Mui-selected': {
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    '& .MuiTabs-indicator': {
        height: 5,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },
}));

const LineupOptimizer: React.FC = () => {
    const { slateId, slateType } = useSlateContext();
    const { persistentState, updatePersistentState } = usePersistentState();
    const { stackingInfo, numberOfLineups, value } = persistentState;
    const isShowdown = slateType === 'Showdown';



    useEffect(() => {
        // This effect ensures that we're using the latest persistent state
        // It's empty because we're just ensuring the component re-renders when persistentState changes
    }, [persistentState]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        updatePersistentState({ value: newValue });
    };

    const memoizedStackingInfo = useMemo(() => stackingInfo, [stackingInfo]);

    const memoizedUpdatePersistentState = useCallback((updates: Partial<any>) => {
        updatePersistentState(updates);
    }, [updatePersistentState]);



    useEffect(() => {
        if (persistentState.stackingInfo.showdownStackRules) {
            updatePersistentState({
                stackingInfo: {
                    ...persistentState.stackingInfo,
                    showdownStackRules: persistentState.stackingInfo.showdownStackRules
                }
            });
        }
    }, [persistentState.stackingInfo.showdownStackRules, updatePersistentState]);

    // useEffect(() => {
    //     // console.log('Players in LineupOptimizer:', players);
    // }, [players]);

    const handlePlayersUpdate = (updatedPlayers: Player[]) => {
        updatePersistentState({ players: updatedPlayers });
    };
    // useEffect(() => {
    //     console.log('showdownStackRules in LineupOptimizer:', showdownStackRules);
    // }, [showdownStackRules]);



    return (
        <SettingsProvider>
            <Container maxWidth="xl" disableGutters>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    pb: 12,
                    pt: 8
                }}>
                    <Box sx={{
                        width: '100%',
                        mb: 0
                    }}>
                        <StyledTabs
                            value={persistentState.value}
                            onChange={handleChange}
                            aria-label="lineup optimizer tabs"
                            sx={{
                                position: 'sticky',
                                top: 64,
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                            }}
                        >
                            <Tab label="Player Pool" />
                            <Tab label="Settings" />
                            {isShowdown ? (
                                <Tab label="Showdown Stacking" />
                            ) : (
                                <Tab label="Stacking" />
                            )}
                            <Tab label="Grouping" />
                        </StyledTabs>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        {persistentState.value === 0 && (
                            <PlayerPool
                                stackingInfo={persistentState.stackingInfo}
                                numberOfLineups={persistentState.numberOfLineups}
                                updatePersistentState={updatePersistentState}
                                isShowdown={isShowdown}
                                onPlayersUpdate={handlePlayersUpdate}
                                showdownStackRules={persistentState.stackingInfo.showdownStackRules}
                            />
                        )}
                        {persistentState.value === 1 && (
                            isShowdown ? (
                                <ShowdownSettings
                                // updatePersistentState={updatePersistentState}
                                // settings={persistentState.settings}
                                />
                            ) : (
                                <Settings />
                            )
                        )}
                        {persistentState.value === 2 && (
                            isShowdown ? (
                                <ShowdownStacking
                                    initialRules={persistentState.stackingInfo.showdownStackRules}
                                />
                            ) : (
                                <Stacking
                                    slateId={slateId}
                                />
                            )
                        )}
                        {persistentState.value === 3 && (
                            <Grouping />)}
                    </Box>
                </Box>
            </Container>
        </SettingsProvider>
    );
}

const MemoizedLineupOptimizer = React.memo(LineupOptimizer);


export default LineupOptimizer;