import React, { useEffect, useCallback } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, FormControlLabel, TextField, Card, CardContent, Switch, Grid, Tooltip, Chip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useSlateContext } from '../../context/SlateContext';
import { useAuth } from '../../context/AuthContext';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { usePersistentState } from '../../context/PersistentStateContext';
import { NFLTeamStack, Player, StackingInfo, TeamStack } from '../../types';

// interface Player {
//     id: string;
//     Order?: number;
//     Player: string;
//     teamAbbreviation: string;
//     position: string;
//     opponent: string;
//     playerPosition: string;
//     Proj: number;
//     Team: string;
// }



// interface StackingProps {
//     slateId: number | null;
//     nflTeamStacks: NFLTeamStack[];
//     setNFLTeamStacks: React.Dispatch<React.SetStateAction<NFLTeamStack[]>>;
// }

interface StackingProps {
    slateId: number | null;
    // nflTeamStacks: NFLTeamStack[];
    // setNFLTeamStacks: React.Dispatch<React.SetStateAction<NFLTeamStack[]>>;
}

// interface StackingInfo {
//     primaryStacks: string[];
//     secondaryStacks: string[];
//     usePrimaryStacks: boolean;
//     useSecondaryStacks: boolean;
//     primaryStackCount: number | null;
//     secondaryStackCount: number | null;
//     useBringBack: boolean;
//     rbDstStack: boolean;
//     bringBackSize: number;
// }

function Stacking({ slateId }: StackingProps) {
    const { isLoggedIn } = useAuth();
    const { persistentState, updatePersistentState } = usePersistentState();
    const { sport } = useSlateContext();
    const [allQBStacksChecked, setAllQBStacksChecked] = React.useState(false);

    const {
        stackingInfo,
        players,
        games,
        nflTeamStacks,
        settings: { minQBStackSize, maxQBStackSize, globalBringBackSize }
    } = persistentState;

    type Stack = NFLTeamStack | TeamStack;

    useEffect(() => {
        if (isLoggedIn && slateId) {
            fetchPlayers();
        }
    }, [isLoggedIn, slateId, sport]);

    useEffect(() => {
        // Update persistent state whenever nflTeamStacks changes
        updatePersistentState({
            stackingInfo: {
                ...stackingInfo,
                nflTeamStacks: nflTeamStacks,
            },
        });
    }, [nflTeamStacks]);


    const fetchPlayers = async () => {
        if (!slateId) return;
        try {
            const playersQuery = query(collection(db, 'data_players'), where('slate_identifier', '==', slateId));
            const playerSnapshot = await getDocs(playersQuery);
            const playerList = playerSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as Player));

            if (sport === 'NFL') {
                const processedNFLTeamStacks = processNFLPlayersIntoTeamStacks(playerList);
                updatePersistentState({ nflTeamStacks: processedNFLTeamStacks });
            }
            else if (sport === 'MLB' || sport === 'NBA') {
                const processedMLBTeamStacks = processPlayersIntoTeamStacks(playerList);
                updatePersistentState({
                    stackingInfo: {
                        ...stackingInfo,
                        teamStacks: processedMLBTeamStacks
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching players:', error);
        }
    };

    const processPlayersIntoTeamStacks = (players: Player[]): TeamStack[] => {
        const teamMap = new Map<string, Player[]>();
        if (sport === 'NBA') {
            // Group starters by team for NBA
            players.forEach(player => {
                const team = player.teamAbbreviation;
                const position = (player.position || '').toLowerCase();


                // Only include starters
                if (!team || !position.includes('start')) return;

                if (!teamMap.has(team)) {
                    teamMap.set(team, []);
                }
                teamMap.get(team)?.push(player);
            });

            // Process NBA teams
            return Array.from(teamMap).map(([team, teamPlayers]) => ({
                team,
                // Take top 5 players by salary, pad with nulls if needed
                players: teamPlayers
                    .sort((a, b) => (b.Salary || 0) - (a.Salary || 0))
                    .slice(0, 5)
                    .concat(Array(5).fill(null))
                    .slice(0, 5),
                isPrimaryStack: stackingInfo.primaryStacks.includes(team),
                isSecondaryStack: false, // No secondary stacks for NBA
            }));
        } else {
            players.forEach(player => {
                const team = player.Team;
                if (!team) return;

                if (!teamMap.has(team)) {
                    teamMap.set(team, []);
                }
                teamMap.get(team)?.push(player);
            });

            return Array.from(teamMap).map(([team, players]) => ({
                team,
                players: Array(9).fill(null).map((_, index) =>
                    players.find(p => p.Order === index + 1) || null
                ),
                isPrimaryStack: stackingInfo.primaryStacks.includes(team),
                isSecondaryStack: stackingInfo.secondaryStacks.includes(team),
            }));
        }
    };

    const processNFLPlayersIntoTeamStacks = (players: Player[]): NFLTeamStack[] => {
        const teamMap = new Map<string, Player[]>();
        const opponentMap = new Map<string, string>();

        players.forEach(player => {
            const team = player.teamAbbreviation || player.Team;
            const opponent = (player.opponent || player.Opp || '').replace(/^(vs |@ )/, '');
            if (!team || !opponent) return;

            if (!teamMap.has(team)) {
                teamMap.set(team, []);
                opponentMap.set(team, opponent);
            }
            teamMap.get(team)?.push(player);
        });

        return Array.from(teamMap).map(([team, teamPlayers]): NFLTeamStack => {
            const opponent = opponentMap.get(team) || '';
            const existingStack = stackingInfo.nflTeamStacks.find(s => s.team === team);

            return {
                team,
                opponent,
                players: teamPlayers,
                isPrimaryStack: existingStack?.isPrimaryStack || false,
                isSecondaryStack: existingStack?.isSecondaryStack || false,
                allPlayersIncluded: existingStack?.allPlayersIncluded || false,
                minQBStackSize: existingStack?.minQBStackSize || 1,  // Default to 1
                maxQBStackSize: existingStack?.maxQBStackSize || 3,  // Default to 3
                bringBackSize: existingStack?.bringBackSize || 0,
                allTeammatesSelected: existingStack?.allTeammatesSelected || false,
                allOpponentsSelected: existingStack?.allOpponentsSelected || false,
                eligibleTeammates: existingStack?.eligibleTeammates || new Set(teamPlayers.filter(p => p.playerPosition === 'QB').map(p => p.Player)),
                eligibleOpponents: existingStack?.eligibleOpponents || new Set(),
            };
        });
    };

    const handleStackSizeChange = (index: number, field: 'minQBStackSize' | 'maxQBStackSize' | 'bringBackSize', value: number) => {
        updatePersistentState(prevState => {
            const newNFLTeamStacks = [...prevState.nflTeamStacks];
            const stack = newNFLTeamStacks[index];

            if (field === 'bringBackSize') {
                if (stack.eligibleOpponents.size > 0) {
                    value = Math.max(1, value);
                }
                stack[field] = Math.min(Math.max(0, value), 3);
            } else {
                stack[field] = Math.min(Math.max(0, value), 4);

                // Ensure min is not greater than max and max is not less than min
                if (field === 'minQBStackSize' && stack.maxQBStackSize < value) {
                    stack.maxQBStackSize = value;
                } else if (field === 'maxQBStackSize' && stack.minQBStackSize > value) {
                    stack.minQBStackSize = value;
                }
            }

            return { nflTeamStacks: newNFLTeamStacks };
        });
    };

    const processNFLPlayers = (players: Player[]): Player[] => {
        const positionOrder = ['QB', 'RB', 'WR', 'TE'];
        return players.sort((a, b) => {
            const aPosition = a.playerPosition || a.Pos || '';
            const bPosition = b.playerPosition || b.Pos || '';
            const aIndex = positionOrder.indexOf(aPosition);
            const bIndex = positionOrder.indexOf(bPosition);
            return aIndex - bIndex;
        });
    };

    const handleGlobalQBStackSizeChange = (type: 'min' | 'max', value: string) => {
        const numValue = parseInt(value, 10);
        if (isNaN(numValue) || numValue < 0 || numValue > 4) return;

        updatePersistentState(prevState => {
            const settingKey = type === 'min' ? 'minQBStackSize' : 'maxQBStackSize';
            const otherKey = type === 'min' ? 'maxQBStackSize' : 'minQBStackSize';

            // Ensure min doesn't exceed max and max doesn't go below min
            const newValue = type === 'min'
                ? Math.min(numValue, prevState.settings[otherKey])
                : Math.max(numValue, prevState.settings[otherKey]);

            return {
                settings: {
                    ...prevState.settings,
                    [settingKey]: newValue
                },
                nflTeamStacks: prevState.nflTeamStacks.map(stack => ({
                    ...stack,
                    [settingKey]: newValue
                }))
            };
        });
    };

    const handleIndividualQBStackSizeChange = (index: number, type: 'min' | 'max', value: string) => {
        const numValue = parseInt(value, 10);
        if (isNaN(numValue) || numValue < 0 || numValue > 4) return;

        updatePersistentState(prevState => ({
            ...prevState,
            nflTeamStacks: prevState.nflTeamStacks.map((stack, i) =>
                i === index
                    ? { ...stack, [type === 'min' ? 'minQBStackSize' : 'maxQBStackSize']: numValue }
                    : stack
            )
        }));
    };

    const handleCheckAllQBStacks = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setAllQBStacksChecked(checked);
        updatePersistentState(prevState => {
            const newNFLTeamStacks = prevState.nflTeamStacks.map(stack => ({
                ...stack,
                isPrimaryStack: checked,
                eligibleTeammates: checked
                    ? new Set(stack.players.filter(p => p !== null && p.Proj > 3 && p.playerPosition !== 'DST').map(p => p!.Player))
                    : new Set(stack.players[0] ? [stack.players[0].Player] : []),
                eligibleOpponents: checked
                    ? new Set(prevState.nflTeamStacks.find(s => s.team === stack.opponent)?.players.filter(p => p !== null && p.Proj > 3 && p.playerPosition !== 'QB' && p.playerPosition !== 'DST').map(p => p!.Player) || [])
                    : new Set<string>(),
                allTeammatesSelected: checked,
                allOpponentsSelected: checked,
                minQBStackSize: prevState.settings.minQBStackSize,
                maxQBStackSize: prevState.settings.maxQBStackSize,
                bringBackSize: prevState.settings.globalBringBackSize
            }));

            return {
                nflTeamStacks: newNFLTeamStacks,
                stackingInfo: {
                    ...prevState.stackingInfo,
                    primaryStacks: checked ? newNFLTeamStacks.map(stack => stack.team) : []
                }
            };
        });
    };

    const handleStackingChange = (updates: Partial<typeof stackingInfo>) => {
        updatePersistentState({
            stackingInfo: { ...stackingInfo, ...updates },
        });
    };

    // const handleGlobalQBStackSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const newSize = Math.min(Math.max(1, Number(event.target.value)), 4);
    //     updatePersistentState(prevState => ({
    //         settings: {
    //             ...prevState.settings,
    //             globalQBStackSize: newSize
    //         },
    //         nflTeamStacks: prevState.nflTeamStacks.map(stack => ({
    //             ...stack,
    //             qbStackSize: newSize
    //         }))
    //     }));
    // };

    const handleGlobalBringBackSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSize = Math.min(Math.max(0, Number(event.target.value)), 3);
        updatePersistentState(prevState => ({
            settings: {
                ...prevState.settings,
                globalBringBackSize: newSize
            },
            nflTeamStacks: prevState.nflTeamStacks.map(stack => ({
                ...stack,
                bringBackSize: newSize
            }))
        }));
    };

    useEffect(() => {
        const allChecked = nflTeamStacks.every(stack => stack.isPrimaryStack);
        setAllQBStacksChecked(allChecked);
    }, [nflTeamStacks]);


    const handleStackToggle = useCallback((index: number, stackType: 'primary' | 'secondary') => {
        updatePersistentState(prevState => {
            if (sport === 'NBA' || sport === 'MLB') {
                // Clone the existing team stacks
                const newTeamStacks = [...prevState.stackingInfo.teamStacks];
                const stack = newTeamStacks[index];

                if (!stack) {
                    console.error('Stack not found at index:', index);
                    return prevState; // Return unchanged state if stack not found
                }

                if (stackType === 'primary') {
                    stack.isPrimaryStack = !stack.isPrimaryStack;
                } else if (sport === 'MLB') {  // Only handle secondary stacks for MLB
                    stack.isSecondaryStack = !stack.isSecondaryStack;
                }

                const updatedStackingInfo = {
                    ...prevState.stackingInfo,
                    teamStacks: newTeamStacks,
                    primaryStacks: newTeamStacks.filter(s => s.isPrimaryStack).map(s => s.team),
                    secondaryStacks: sport === 'MLB'
                        ? newTeamStacks.filter(s => s.isSecondaryStack).map(s => s.team)
                        : []
                };

                return {
                    ...prevState,
                    stackingInfo: updatedStackingInfo
                };
            } else if (sport === 'NFL') {
                // Existing NFL logic
                const newNFLTeamStacks = [...prevState.nflTeamStacks];
                const stack = newNFLTeamStacks[index];
                if (stackType === 'primary') {
                    stack.isPrimaryStack = !stack.isPrimaryStack;
                } else {
                    stack.isSecondaryStack = !stack.isSecondaryStack;
                }

                const updatedStackingInfo = {
                    ...prevState.stackingInfo,
                    primaryStacks: newNFLTeamStacks.filter(s => s.isPrimaryStack).map(s => s.team),
                    secondaryStacks: newNFLTeamStacks.filter(s => s.isSecondaryStack).map(s => s.team),
                };

                return {
                    nflTeamStacks: newNFLTeamStacks,
                    stackingInfo: updatedStackingInfo
                };
            }

            return prevState; // Return unchanged state if sport not handled
        });
    }, [sport, updatePersistentState]);

    const handleAllTeammatesToggle = (index: number) => {
        updatePersistentState(prevState => {
            const newNFLTeamStacks = [...prevState.nflTeamStacks];
            const stack = newNFLTeamStacks[index];
            const allTeammates = stack.players.filter(p => p !== null && p.playerPosition !== 'QB' && p.playerPosition !== 'DST' && p.Proj > 3).map(p => p!.Player);

            stack.allTeammatesSelected = !stack.allTeammatesSelected;
            if (stack.allTeammatesSelected) {
                stack.eligibleTeammates = new Set([...stack.eligibleTeammates, ...allTeammates]);
            } else {
                stack.eligibleTeammates = new Set(stack.players[0] ? [stack.players[0].Player] : []);
            }

            return { nflTeamStacks: newNFLTeamStacks };
        });
    };

    const handleAllOpponentsToggle = (index: number) => {
        updatePersistentState(prevState => {
            const newNFLTeamStacks = [...prevState.nflTeamStacks];
            const stack = newNFLTeamStacks[index];
            const opponentStack = newNFLTeamStacks.find(s => s.team === stack.opponent);
            const allOpponents = opponentStack?.players.filter(p => p !== null && p.playerPosition !== 'QB' && p.playerPosition !== 'DST' && p.Proj > 3).map(p => p!.Player) || [];

            stack.allOpponentsSelected = !stack.allOpponentsSelected;
            if (stack.allOpponentsSelected) {
                stack.eligibleOpponents = new Set(allOpponents);
            } else {
                stack.eligibleOpponents = new Set();
            }

            return { nflTeamStacks: newNFLTeamStacks };
        });
    };


    const handleNFLStackToggle = (index: number, stackType: 'primary' | 'secondary') => {
        updatePersistentState(prevState => {
            const newNFLTeamStacks = [...prevState.nflTeamStacks];
            const stack = newNFLTeamStacks[index];
            const newIsPrimaryStack = stackType === 'primary' ? !stack.isPrimaryStack : stack.isPrimaryStack;

            stack.isPrimaryStack = newIsPrimaryStack;
            stack.isSecondaryStack = stackType === 'secondary' ? !stack.isSecondaryStack : stack.isSecondaryStack;

            if (newIsPrimaryStack) {
                stack.eligibleTeammates.add(stack.players[0]!.Player);
            } else {
                stack.eligibleTeammates = new Set(stack.players[0] ? [stack.players[0].Player] : []);
            }

            const updatedStackingInfo = {
                ...prevState.stackingInfo,
                primaryStacks: newNFLTeamStacks.filter(s => s.isPrimaryStack).map(s => s.team),
                secondaryStacks: newNFLTeamStacks.filter(s => s.isSecondaryStack).map(s => s.team),
            };

            return {
                nflTeamStacks: newNFLTeamStacks,
                stackingInfo: updatedStackingInfo
            };
        });
    };

    const handleAllPlayersToggle = (index: number) => {
        updatePersistentState(prevState => {
            const newNFLTeamStacks = [...prevState.nflTeamStacks];
            const stack = newNFLTeamStacks[index];
            stack.allPlayersIncluded = !stack.allPlayersIncluded;
            if (stack.allPlayersIncluded) {
                stack.eligibleTeammates = new Set(stack.players.filter(p => p !== null).map(p => p!.Player));
                stack.eligibleOpponents = new Set(prevState.nflTeamStacks.find(s => s.team === stack.opponent)?.players
                    .filter(p => p !== null && p.position !== 'QB-1')
                    .map(p => p!.Player) || []);
            } else {
                stack.eligibleTeammates = new Set(stack.players[0] ? [stack.players[0].Player] : []);
                stack.eligibleOpponents = new Set();
            }
            return { nflTeamStacks: newNFLTeamStacks };
        });
    };

    const handlePlayerToggle = (stackIndex: number, playerName: string, isOpponent: boolean) => {
        updatePersistentState(prevState => {
            const newNFLTeamStacks = [...prevState.nflTeamStacks];
            const stack = newNFLTeamStacks[stackIndex];
            const eligibleSet = isOpponent ? stack.eligibleOpponents : stack.eligibleTeammates;

            if (eligibleSet.has(playerName)) {
                eligibleSet.delete(playerName);
            } else {
                eligibleSet.add(playerName);
                // If an opponent is being added and the Bring Back Size is 0, set it to 1
                if (isOpponent && stack.bringBackSize === 0) {
                    stack.bringBackSize = 1;
                }
            }

            // Update allTeammatesSelected and allOpponentsSelected
            const opponentStack = newNFLTeamStacks.find(s => s.team === stack.opponent);
            if (opponentStack) {
                stack.allTeammatesSelected = stack.players.every(p =>
                    p === null ||
                    p.playerPosition === 'QB' ||
                    p.playerPosition === 'DST' ||
                    stack.eligibleTeammates.has(p.Player)
                );
                stack.allOpponentsSelected = opponentStack.players.every(p =>
                    p === null ||
                    p.playerPosition === 'QB' ||
                    p.playerPosition === 'DST' ||
                    stack.eligibleOpponents.has(p.Player)
                );
            }

            return { nflTeamStacks: newNFLTeamStacks };
        });
    };

    const handleNBAStackSizeChange = (index: number, type: 'min' | 'max', value: number) => {
        updatePersistentState(prevState => {
            const newTeamStacks = [...prevState.stackingInfo.teamStacks];
            const stack = newTeamStacks[index];

            if (!stack) return prevState;

            const newValue = Math.min(Math.max(2, value), 5);

            if (type === 'min') {
                stack.minStackSize = Math.min(newValue, stack.maxStackSize || 5);
            } else {
                stack.maxStackSize = Math.max(newValue, stack.minStackSize || 2);
            }

            return {
                ...prevState,
                stackingInfo: {
                    ...prevState.stackingInfo,
                    teamStacks: newTeamStacks
                }
            };
        });
    };

    const renderOpponents = (stack: NFLTeamStack) => {
        // console.log(`Rendering opponents for team: ${stack.team}, looking for opponent: ${stack.opponent}`);
        // console.log('All available teams:', nflTeamStacks.map(s => s.team).join(', '));
        const opponentStack = nflTeamStacks.find(s => s.team === stack.opponent);
        // console.log(`Opponent stack:`, opponentStack);
        if (!opponentStack) {
            // console.log(`No opponent stack found for ${stack.opponent}`);
            return null;
        }

        const eligibleOpponents = opponentStack.players.filter(player =>
            player !== null &&
            player.Proj > 3 &&
            (player.playerPosition || player.Pos) !== 'QB' &&
            (player.playerPosition || player.Pos) !== 'DST'
        );
        // console.log(`Eligible opponents:`, eligibleOpponents);

        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {eligibleOpponents.map((player) => {
                    const position = player.playerPosition || player.Pos || '';
                    return (
                        <Chip
                            key={`opponent-${stack.opponent}-${player.id}`}
                            label={player.Player}
                            onClick={() => handlePlayerToggle(nflTeamStacks.indexOf(stack), player.Player, true)}
                            color={stack.eligibleOpponents.has(player.Player) ? "primary" : "default"}
                            size="small"
                            disabled={!stack.isPrimaryStack}
                        />
                    );
                })}
            </Box>
        );
    };

    return (
        <Box sx={{ maxWidth: '90%', overflowX: 'auto' }}>
            <Card elevation={3} sx={{ mb: 1 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Stacking Options</Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={stackingInfo.usePrimaryStacks}
                                        onChange={(e) => handleStackingChange({ usePrimaryStacks: e.target.checked })}
                                        color="primary"
                                    />
                                }
                                label={
                                    sport === 'NBA'
                                        ? "Use Team Stacking"
                                        : sport === 'NFL'
                                            ? "Use QB Stacks"
                                            : "Use Primary Stacks"
                                }
                            />
                        </Grid>
                        {sport === 'MLB' && (
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={stackingInfo.useSecondaryStacks}
                                            onChange={(e) => handleStackingChange({ useSecondaryStacks: e.target.checked })}
                                            color="secondary"
                                        />
                                    }
                                    label="Use Secondary Stacks"
                                />
                            </Grid>
                        )}
                        {sport === 'NFL' && (
                            <Grid item xs={12} sm={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={allQBStacksChecked}
                                            onChange={handleCheckAllQBStacks}
                                            color="primary"
                                        />
                                    }
                                    label="Add All Stacks"
                                />
                            </Grid>
                        )}
                        {sport === 'NFL' && (
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={2}>
                                    <Typography variant="subtitle2">Global Min QB Stack Size</Typography>
                                    <TextField
                                        type="number"
                                        value={persistentState.settings.minQBStackSize}
                                        onChange={(e) => handleGlobalQBStackSizeChange('min', e.target.value)}
                                        inputProps={{ min: 0, max: 4 }}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="subtitle2">Global Max QB Stack Size</Typography>
                                    <TextField
                                        type="number"
                                        value={persistentState.settings.maxQBStackSize}
                                        onChange={(e) => handleGlobalQBStackSizeChange('max', e.target.value)}
                                        inputProps={{ min: 0, max: 4 }}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                    <Typography variant="subtitle2">Update All Bring Back Size</Typography>
                                    <TextField
                                        type="number"
                                        value={globalBringBackSize}
                                        onChange={handleGlobalBringBackSizeChange}
                                        inputProps={{ min: 0, max: 3 }}
                                        size="small"
                                        fullWidth
                                    />
                                </Grid></Grid>
                        )}
                        {/* {sport !== 'NFL' && (
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={stackingInfo.useSecondaryStacks}
                                            onChange={(e) => handleStackingChange({ useSecondaryStacks: e.target.checked })}
                                            color="secondary"
                                        />
                                    }
                                    label="Use Secondary Stacks"
                                />
                            </Grid>
                        )} */}
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {sport !== 'NFL' && sport !== 'NBA' && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label={sport === 'NBA' ? "Stack Size" : "Primary Stack Size"}
                                    type="number"
                                    value={stackingInfo.primaryStackCount ?? ''}
                                    onChange={(e) => {
                                        const value = Math.min(Math.max(1, Number(e.target.value)), 5);
                                        handleStackingChange({ primaryStackCount: value });
                                    }}
                                    disabled={!stackingInfo.usePrimaryStacks}
                                    size="small"
                                    fullWidth
                                    inputProps={{ min: 1, max: 5 }}
                                />
                            </Grid>
                        )}
                        {sport === 'MLB' && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Secondary Stack Size"
                                    type="number"
                                    value={stackingInfo.secondaryStackCount ?? ''}
                                    onChange={(e) => handleStackingChange({ secondaryStackCount: e.target.value === '' ? null : Number(e.target.value) })}
                                    disabled={!stackingInfo.useSecondaryStacks}
                                    size="small"
                                    fullWidth
                                    inputProps={{ min: 1, max: 3 }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>

            <Card elevation={3}>
                <CardContent>
                    <TableContainer>
                        <Table size="small" aria-label="stacking table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{sport === 'NFL' ? "QB Stack" : "Primary"}</TableCell>
                                    {sport !== 'NFL' && sport !== 'NBA' && <TableCell>Secondary</TableCell>}
                                    <TableCell>Team</TableCell>
                                    {sport === 'NBA' && (
                                        <>
                                            <TableCell>Min Size</TableCell>
                                            <TableCell>Max Size</TableCell>
                                        </>
                                    )}
                                    {sport === 'NFL' ? (
                                        <>
                                            <TableCell>All Teammates</TableCell>
                                            <TableCell>Min QB Stack Size</TableCell>
                                            <TableCell>Max QB Stack Size</TableCell>
                                            <TableCell>Teammates</TableCell>
                                            <TableCell>Opponent</TableCell>
                                            <TableCell>All Opponents</TableCell>
                                            <TableCell>Bring Back Size</TableCell>
                                            <TableCell>Opponents</TableCell>
                                        </>
                                    ) : (
                                        Array.from({ length: sport === 'NBA' ? 5 : 9 }, (_, i) => (
                                            <TableCell key={`header-${i + 1}`} align="center">
                                                {sport === 'NBA' ? `${i + 1}` : i + 1}
                                            </TableCell>
                                        ))
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(sport === 'NFL' ? nflTeamStacks : stackingInfo.teamStacks).map((stack: Stack, stackIndex: number) => (
                                    <TableRow key={`team-${stack.team}-${stackIndex}`} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={stack.isPrimaryStack}
                                                onChange={() => handleStackToggle(stackIndex, 'primary')}
                                                disabled={!stackingInfo.usePrimaryStacks}
                                                size="small"
                                            />
                                        </TableCell>
                                        {sport === 'MLB' && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={stack.isSecondaryStack}
                                                    onChange={() => handleStackToggle(stackIndex, 'secondary')}
                                                    disabled={!stackingInfo.useSecondaryStacks}
                                                    size="small"
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell component="th" scope="row" sx={{ typography: 'body2' }}>
                                            {stack.team}
                                        </TableCell>
                                        {sport === 'NBA' ? (
                                            <>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={(stack as TeamStack).minStackSize}
                                                        onChange={(e) => handleNBAStackSizeChange(stackIndex, 'min', parseInt(e.target.value))}
                                                        inputProps={{ min: 2, max: 5 }}
                                                        disabled={!stack.isPrimaryStack}
                                                        size="small"
                                                        sx={{ width: '60px' }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={(stack as TeamStack).maxStackSize}
                                                        onChange={(e) => handleNBAStackSizeChange(stackIndex, 'max', parseInt(e.target.value))}
                                                        inputProps={{ min: 2, max: 5 }}
                                                        disabled={!stack.isPrimaryStack}
                                                        size="small"
                                                        sx={{ width: '60px' }}
                                                    />
                                                </TableCell>
                                            </>
                                        ) : null}
                                        {sport === 'NFL' ? (
                                            <>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={(stack as NFLTeamStack).allTeammatesSelected}
                                                        onChange={() => handleAllTeammatesToggle(stackIndex)}
                                                        disabled={!stack.isPrimaryStack}
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={(stack as NFLTeamStack).minQBStackSize}
                                                        onChange={(e) => handleStackSizeChange(stackIndex, 'minQBStackSize', parseInt(e.target.value))}
                                                        inputProps={{ min: 0, max: 4 }}
                                                        disabled={!stack.isPrimaryStack}
                                                        size="small"
                                                        sx={{ width: '60px', mr: 1 }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={(stack as NFLTeamStack).maxQBStackSize}
                                                        onChange={(e) => handleStackSizeChange(stackIndex, 'maxQBStackSize', parseInt(e.target.value))}
                                                        inputProps={{ min: 0, max: 4 }}
                                                        disabled={!stack.isPrimaryStack}
                                                        size="small"
                                                        sx={{ width: '60px' }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {(stack as NFLTeamStack).players
                                                            .filter((player): player is Player =>
                                                                player !== null &&
                                                                player.Proj > 3 &&
                                                                (player.playerPosition || player.Pos) !== 'DST'
                                                            )
                                                            .sort((a, b) =>
                                                                (a.playerPosition || a.Pos) === 'QB' ? -1 :
                                                                    (b.playerPosition || b.Pos) === 'QB' ? 1 : 0
                                                            )
                                                            .map((player, playerIndex) => {
                                                                const position = player.playerPosition || player.Pos || '';
                                                                return (
                                                                    <Chip
                                                                        key={`player-${stack.team}-${player.id}`}
                                                                        label={player.Player}
                                                                        onClick={() => position !== 'QB' && handlePlayerToggle(stackIndex, player.Player, false)}
                                                                        color={position === 'QB' ? "secondary" : (stack as NFLTeamStack).eligibleTeammates.has(player.Player) ? "primary" : "default"}
                                                                        size="small"
                                                                        sx={position === 'QB' ? { pointerEvents: 'none' } : {}}
                                                                        disabled={!stack.isPrimaryStack}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                    </Box>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ typography: 'body2' }}>
                                                    {(stack as NFLTeamStack).opponent}
                                                </TableCell>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={(stack as NFLTeamStack).allOpponentsSelected}
                                                        onChange={() => handleAllOpponentsToggle(stackIndex)}
                                                        disabled={!stack.isPrimaryStack}
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={(stack as NFLTeamStack).bringBackSize}
                                                        onChange={(e) => handleStackSizeChange(stackIndex, 'bringBackSize', parseInt(e.target.value))}
                                                        inputProps={{ min: (stack as NFLTeamStack).eligibleOpponents.size > 0 ? 1 : 0, max: 3 }}
                                                        disabled={!stack.isPrimaryStack || (stack as NFLTeamStack).eligibleOpponents.size === 0}
                                                        size="small"
                                                        sx={{ width: '60px' }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {renderOpponents(stack as NFLTeamStack)}
                                                </TableCell>
                                            </>
                                        ) : (
                                            stack.players.map((player, playerIndex) => (
                                                <TableCell
                                                    key={`player-${stack.team}-${playerIndex}`}
                                                    align="center"
                                                    sx={{ typography: 'body2', whiteSpace: 'nowrap' }}
                                                >
                                                    {player ? player.Player : '-'}
                                                </TableCell>
                                            ))
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
}

export default Stacking;