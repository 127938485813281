import React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Typography, Paper, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faSackDollar, faRankingStar } from '@fortawesome/free-solid-svg-icons';

interface StackFrequencyTableProps {
    data: any[];
}

const StackFrequencyTable: React.FC<StackFrequencyTableProps> = ({ data }) => {
    const getBarColor = (percentage: number) => {
        if (percentage >= 80) return 'linear-gradient(90deg, #4CAF50, #8BC34A)';
        if (percentage <= 10) return 'linear-gradient(90deg, #F44336, #FF9800)';
        return 'linear-gradient(90deg, #FF9800, #FFC107)';
    };

    const renderBarChart = (value: number, maxValue: number) => {
        const percentage = Math.min((value / maxValue) * 100, 100);
        const barColor = getBarColor(percentage);

        return (
            <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: `${percentage}%`,
                        height: '100%',
                        background: barColor,
                        opacity: 0.7,
                        transition: 'width 0.3s ease-in-out',
                    }}
                />
                <Typography
                    variant="body2"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'text.primary',
                        fontWeight: 'bold',
                        textShadow: '0px 0px 2px white',
                    }}
                >
                    {`${value.toFixed(2)}%`}
                </Typography>
            </Box>
        );
    };

    const getAvgBestFinishStyle = (value: number) => {
        let style: React.CSSProperties = {};
        if (value <= 20) {
            style.color = 'green';
        } else if (value > 70) {
            style.color = 'red';
        } else {
            style.color = 'orange';
        }
        return style;
    };

    const columns: GridColDef[] = [
        { field: 'main_stack', headerName: 'Stack', width: 100 },
        {
            field: 'first_pct',
            headerName: '',
            width: 120,
            renderHeader: () => (
                <Tooltip title="Win%">
                    <Box display="flex" justifyContent="center" width="100%">
                        <FontAwesomeIcon icon={faTrophy} style={{ fontSize: '1.5rem', color: '#dc5f00' }} />
                    </Box>
                </Tooltip>
            ),
            renderCell: (params) => renderBarChart(params.value, 10),
        },
        {
            field: 'top_5_pct',
            headerName: '',
            width: 120,
            renderHeader: () => (
                <Tooltip title="Top 5 Finish %">
                    <Box display="flex" justifyContent="center" width="100%">
                        <Typography variant="h6" style={{ color: '#dc5f00', fontWeight: 'bold' }}>5%</Typography>
                    </Box>
                </Tooltip>
            ),
            renderCell: (params) => renderBarChart(params.value, 50),
        },
        {
            field: 'top_25_pct',
            headerName: '',
            width: 120,
            renderHeader: () => (
                <Tooltip title="Top 25 Finish %">
                    <Box display="flex" justifyContent="center" width="100%">
                        <FontAwesomeIcon icon={faSackDollar} style={{ fontSize: '1.5rem', color: '#dc5f00' }} />
                    </Box>
                </Tooltip>
            ),
            renderCell: (params) => renderBarChart(params.value, 80),
        },
        {
            field: 'avg_best_rank',
            headerName: '',
            width: 120,
            renderHeader: () => (
                <Tooltip title="Avg Best Finish">
                    <Box display="flex" justifyContent="center" width="100%">
                        <FontAwesomeIcon icon={faRankingStar} style={{ fontSize: '1.5rem', color: '#dc5f00' }} />
                    </Box>
                </Tooltip>
            ),
            renderCell: (params: GridRenderCellParams) => {
                const value = params.value != null ? Number(params.value) : 0;
                const style = getAvgBestFinishStyle(value);
                return (
                    <div style={style}>
                        {value.toFixed(2)}
                    </div>
                );
            },
        },
    ];

    const rows = data
        .sort((a, b) => a['avg_best_rank'] - b['avg_best_rank'])
        .slice(0, 25)
        .map((row, index) => ({
            id: row.main_stack || `row-${index}`,
            ...row
        }));

    return (
        <Paper elevation={3} sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 25, page: 0 },
                    },
                }}
                pageSizeOptions={[25]}
                density="compact"
                disableColumnMenu
                sx={{
                    '& .MuiDataGrid-cell': {
                        borderRight: 'none',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#f5f5f5',
                        borderBottom: '2px solid #e0e0e0',
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                        backgroundColor: '#fafafa',
                    },
                }}
            />
        </Paper>
    );
};

export default StackFrequencyTable;