import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    TextField,
    Chip,
    InputLabel,
    FormControl,
    Button
} from '@mui/material';
import { Player, StackRule } from '../../types';
import { usePersistentState } from '../../context/PersistentStateContext';

interface ShowdownStackingProps {
    initialRules: StackRule[];
}

const ShowdownStacking: React.FC<ShowdownStackingProps> = ({ initialRules }) => {
    const { persistentState, updatePersistentState } = usePersistentState();
    const { players, stackingInfo } = persistentState;

    useEffect(() => {
        if (stackingInfo.showdownStackRules.length === 0 && initialRules.length > 0) {
            updatePersistentState({
                stackingInfo: {
                    ...stackingInfo,
                    showdownStackRules: initialRules
                }
            });
        }
    }, [initialRules, stackingInfo, updatePersistentState]);

    const captains = useMemo(() => {
        return players.filter(player => player.Pos === 'CPT');
    }, [players]);

    const nonCaptains = useMemo(() => {
        return players.filter(player => player.Pos !== 'CPT');
    }, [players]);

    const getBasePlayerName = (playerName: string) => {
        return playerName.replace(' - CPT', '');
    };

    const getPlayerTeam = (playerName: string) => {
        const player = players.find(p => p.Player === playerName || p.Player === getBasePlayerName(playerName));
        return player ? player.Team : '';
    };

    const getCaptainTeam = (captainName: string) => {
        return getPlayerTeam(captainName);
    };

    const getChipColor = (playerName: string, isIncluded: boolean, isExcluded: boolean, captainTeam: string) => {
        if (isIncluded) return 'success';
        if (isExcluded) return 'error';

        const playerTeam = getPlayerTeam(playerName);
        if (playerTeam === captainTeam) return 'success';
        if (captainTeam && playerTeam !== captainTeam) return 'error';

        return 'default';
    };

    const getChipStyle = (isDisabled: boolean, isActive: boolean, captainTeam: string, playerName: string) => {
        if (isDisabled) return {
            opacity: 0.5,
            backgroundColor: '#e0e0e0',
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 400
        };

        const playerTeam = getPlayerTeam(playerName);
        const baseStyle = {
            opacity: isActive ? 1 : 0.5,
            color: 'white',
            fontWeight: 500,
            fontSize: '0.85rem',
            textShadow: '0px 0px 2px rgba(0,0,0,0.3)'
        };

        if (playerTeam === captainTeam) return {
            ...baseStyle,
            backgroundColor: isActive ? '#315c33' : '#002902'
        };
        if (captainTeam && playerTeam !== captainTeam) return {
            ...baseStyle,
            backgroundColor: isActive ? '#851010' : '#ed6258'
        };

        return { ...baseStyle, backgroundColor: '#757575' };
    };

    const handleChange = (index: number, field: keyof StackRule, value: any) => {
        const newRules = [...stackingInfo.showdownStackRules];
        if (field === 'cpt') {
            const oldCaptainBase = getBasePlayerName(newRules[index].cpt);
            newRules[index].includePlayers = newRules[index].includePlayers.filter(p => getBasePlayerName(p) !== oldCaptainBase);
            newRules[index].excludePlayers = newRules[index].excludePlayers.filter(p => getBasePlayerName(p) !== oldCaptainBase);
        }
        newRules[index] = { ...newRules[index], [field]: value };
        updatePersistentState({
            stackingInfo: {
                ...stackingInfo,
                showdownStackRules: newRules
            }
        });
    };

    const togglePlayer = (index: number, player: string, isInclude: boolean) => {
        const newRules = [...stackingInfo.showdownStackRules];
        const includeField = 'includePlayers';
        const excludeField = 'excludePlayers';

        if (isInclude) {
            if (newRules[index][includeField].includes(player)) {
                newRules[index][includeField] = newRules[index][includeField].filter(p => p !== player);
            } else {
                newRules[index][includeField] = [...newRules[index][includeField], player];
                newRules[index][excludeField] = newRules[index][excludeField].filter(p => p !== player);
            }
        } else {
            if (newRules[index][excludeField].includes(player)) {
                newRules[index][excludeField] = newRules[index][excludeField].filter(p => p !== player);
            } else {
                newRules[index][excludeField] = [...newRules[index][excludeField], player];
                newRules[index][includeField] = newRules[index][includeField].filter(p => p !== player);
            }
        }

        updatePersistentState({
            stackingInfo: {
                ...stackingInfo,
                showdownStackRules: newRules
            }
        });
    };

    const addNewRule = () => {
        updatePersistentState({
            stackingInfo: {
                ...stackingInfo,
                showdownStackRules: [
                    ...stackingInfo.showdownStackRules,
                    {
                        cpt: '',
                        includeCount: 0,
                        includePlayers: [],
                        excludePlayers: [],
                    }
                ]
            }
        });
    };

    if (players.length === 0) {
        return <Typography>No players available for Showdown Stacking</Typography>;
    }

    return (
        <TableContainer component={Paper}>
            <Button sx={{ mx: 2, mt: 2, mb: 2 }}
                onClick={addNewRule} variant="contained"
                color="secondary"
                size="small">Add New Stack</Button>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Captain</TableCell>
                        <TableCell>Include Count</TableCell>
                        <TableCell>Include Players</TableCell>
                        <TableCell>Exclude Players</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stackingInfo.showdownStackRules.map((rule, index) => {
                        const captainTeam = getCaptainTeam(rule.cpt);
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <FormControl fullWidth>
                                        <InputLabel>Captain</InputLabel>
                                        <Select
                                            value={rule.cpt}
                                            onChange={(e) => handleChange(index, 'cpt', e.target.value)}
                                            label="Captain"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {captains.map(player => (
                                                <MenuItem key={player.id} value={player.Player}>
                                                    {player.Player}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={rule.includeCount}
                                        onChange={(e) => handleChange(index, 'includeCount', parseInt(e.target.value))}
                                        inputProps={{ min: 0, max: 4 }}
                                        disabled={!rule.cpt}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {nonCaptains.map(player => {
                                            const isDisabled = !rule.cpt ||
                                                rule.includeCount === 0 ||
                                                rule.excludePlayers.includes(player.Player) ||
                                                getBasePlayerName(player.Player) === getBasePlayerName(rule.cpt);
                                            const isIncluded = rule.includePlayers.includes(player.Player);
                                            return (
                                                <Chip
                                                    key={player.id}
                                                    label={player.Player}
                                                    onClick={() => togglePlayer(index, player.Player, true)}
                                                    color={getChipColor(player.Player, isIncluded, false, captainTeam)}
                                                    disabled={isDisabled}
                                                    sx={getChipStyle(isDisabled, isIncluded, captainTeam, player.Player)}
                                                />
                                            );
                                        })}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {nonCaptains.map(player => {
                                            const isDisabled = !rule.cpt ||
                                                rule.includePlayers.includes(player.Player) ||
                                                getBasePlayerName(player.Player) === getBasePlayerName(rule.cpt);
                                            const isExcluded = rule.excludePlayers.includes(player.Player);
                                            return (
                                                <Chip
                                                    key={player.id}
                                                    label={player.Player}
                                                    onClick={() => togglePlayer(index, player.Player, false)}
                                                    color={getChipColor(player.Player, false, isExcluded, captainTeam)}
                                                    disabled={isDisabled}
                                                    sx={getChipStyle(isDisabled, isExcluded, captainTeam, player.Player)}
                                                />
                                            );
                                        })}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ShowdownStacking;