import React, { createContext, useState, useContext } from 'react';

interface SlateContextType {
    site: string;
    sport: string;
    date: string | null;
    slateId: number | null;
    slateType: 'Classic' | 'Showdown' | null;
    updateFilters: (site: string, sport: string, date: string | null, slateId: number | null, slateType: 'Classic' | 'Showdown' | null) => void;
}

const SlateContext = createContext<SlateContextType | undefined>(undefined);

export const SlateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [sport, setSport] = useState<string>('');
    const [date, setDate] = useState<string | null>(null);
    const [slateId, setSlateId] = useState<number | null>(null);
    const [slateType, setSlateType] = useState<'Classic' | 'Showdown' | null>(null);  // Add this line
    const [site, setSite] = useState<string>('DraftKings');

    const updateFilters = (newSite: string, newSport: string, newDate: string | null, newSlateId: number | null, newSlateType: 'Classic' | 'Showdown' | null) => {
        setSite(newSite);
        setSport(newSport);
        setDate(newDate);
        setSlateId(newSlateId);
        setSlateType(newSlateType);
    };

    return (
        <SlateContext.Provider value={{ site, sport, date, slateId, slateType, updateFilters }}>
            {children}
        </SlateContext.Provider>
    );
};

export const useSlateContext = () => {
    const context = useContext(SlateContext);
    if (context === undefined) {
        throw new Error('useSlateContext must be used within a SlateProvider');
    }
    return context;
};