import React, { useState, useCallback, useMemo } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    Typography,
    Autocomplete,
    Chip,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Player, PlayerGroup, PlayerPoolGroup } from '../../types';
import { usePersistentState } from '../../context/PersistentStateContext';

const Grouping: React.FC = () => {
    const { persistentState, updatePersistentState } = usePersistentState();
    const { players } = persistentState;



    const handleAddGroup = () => {
        const newGroup: PlayerGroup = {
            id: `group-${Date.now()}`,
            anchorPlayer: null,
            minPlayers: 0,
            maxPlayers: 0,
            includedPlayers: [],
            excludedPlayers: [],
        };

        updatePersistentState({
            playerGroups: [...(persistentState.playerGroups || []), newGroup],
        });
    };

    const handleDeleteGroup = (groupId: string) => {
        updatePersistentState({
            playerGroups: persistentState.playerGroups?.filter(group => group.id !== groupId) || [],
        });
    };

    const handleGroupChange = (groupId: string, field: keyof PlayerGroup, value: any) => {
        updatePersistentState({
            playerGroups: persistentState.playerGroups?.map(group =>
                group.id === groupId ? { ...group, [field]: value } : group
            ) || [],
        });
    };

    const getPlayerName = (playerId: string) => {
        return players.find(p => p.Player === playerId)?.Player || '';
    };

    const handleAddPoolGroup = () => {
        const newGroup: PlayerPoolGroup = {
            id: `pool-${Date.now()}`,
            players: [],
            requiredCount: 1,
        };

        updatePersistentState({
            playerPoolGroups: [...(persistentState.playerPoolGroups || []), newGroup],
        });
    };

    const handleDeletePoolGroup = (groupId: string) => {
        updatePersistentState({
            playerPoolGroups: persistentState.playerPoolGroups?.filter(group => group.id !== groupId) || [],
        });
    };

    const handlePoolGroupChange = (groupId: string, field: keyof PlayerPoolGroup, value: any) => {
        updatePersistentState({
            playerPoolGroups: persistentState.playerPoolGroups?.map(group =>
                group.id === groupId ? { ...group, [field]: value } : group
            ) || [],
        });
    };

    const getPoolGroupHelp = () => {
        return "Create groups of players where each lineup must include a specified number of players from the group. Useful for targeting specific games, value plays, or creating exposure to certain player pools.";
    };

    const getAnchorGroupHelp = () => {
        return "Create groups anchored by a specific player. Each group requires selecting an anchor player and optionally including or excluding other players. The Min and Max values define how many included players must appear with the anchor in each lineup.";
    };

    return (
        <Box sx={{ p: 2, maxWidth: '100%', overflow: 'hidden' }}>
            <Card elevation={3}>
                <CardContent>
                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="h6">Anchor Groups</Typography>
                            <Tooltip title={getAnchorGroupHelp()}>
                                <HelpOutlineIcon color="action" sx={{ fontSize: 20 }} />
                            </Tooltip>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddGroup}
                            size="small"
                        >
                            Add New Group
                        </Button>

                    </Box>

                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Anchor Player</TableCell>
                                    <TableCell>Min Players</TableCell>
                                    <TableCell>Max Players</TableCell>
                                    <TableCell>Include Players</TableCell>
                                    <TableCell>Exclude Players</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {persistentState.playerGroups?.map((group) => (
                                    <TableRow key={group.id}>
                                        <TableCell>
                                            <Autocomplete
                                                value={group.anchorPlayer ? getPlayerName(group.anchorPlayer) : null}
                                                onChange={(_, newValue) => {
                                                    handleGroupChange(group.id, 'anchorPlayer', newValue);
                                                }}
                                                options={players.map(p => p.Player)}
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" label="Select Anchor" />
                                                )}
                                                sx={{ width: 200 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                value={group.minPlayers}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value) || 0;
                                                    const newMin = Math.min(Math.max(0, value), 5);
                                                    handleGroupChange(group.id, 'minPlayers', newMin);
                                                    // Ensure max is at least equal to min
                                                    if (newMin > group.maxPlayers) {
                                                        handleGroupChange(group.id, 'maxPlayers', newMin);
                                                    }
                                                }}
                                                size="small"
                                                inputProps={{ min: 0, max: 5 }}
                                                disabled={!group.anchorPlayer}
                                                sx={{ width: 80 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                value={group.maxPlayers}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value) || 0;
                                                    const newMax = Math.min(Math.max(group.minPlayers, value), 5);
                                                    handleGroupChange(group.id, 'maxPlayers', newMax);
                                                }}
                                                size="small"
                                                inputProps={{ min: group.minPlayers, max: 5 }}
                                                disabled={!group.anchorPlayer}
                                                sx={{ width: 80 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                multiple
                                                value={group.includedPlayers.map(getPlayerName)}
                                                onChange={(_, newValue) => {
                                                    handleGroupChange(group.id, 'includedPlayers',
                                                        newValue.map(name =>
                                                            players.find(p => p.Player === name)?.Player || ''
                                                        )
                                                    );
                                                }}
                                                options={players
                                                    .filter(p => p.Player !== group.anchorPlayer &&
                                                        !group.excludedPlayers.includes(p.Player))
                                                    .map(p => p.Player)}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                            size="small"
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" label="Include Players" />
                                                )}
                                                disabled={!group.anchorPlayer}
                                                sx={{ width: 250 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                multiple
                                                value={group.excludedPlayers.map(getPlayerName)}
                                                onChange={(_, newValue) => {
                                                    handleGroupChange(group.id, 'excludedPlayers',
                                                        newValue.map(name =>
                                                            players.find(p => p.Player === name)?.Player || ''
                                                        )
                                                    );
                                                }}
                                                options={players
                                                    .filter(p => p.Player !== group.anchorPlayer &&
                                                        !group.includedPlayers.includes(p.Player))
                                                    .map(p => p.Player)}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                            size="small"
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} size="small" label="Exclude Players" />
                                                )}
                                                disabled={!group.anchorPlayer}
                                                sx={{ width: 250 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleDeleteGroup(group.id)}
                                                size="small"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <Card elevation={3}>
                <CardContent>
                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="h6">Player Pools</Typography>
                            <Tooltip title={getPoolGroupHelp()}>
                                <HelpOutlineIcon color="action" sx={{ fontSize: 20 }} />
                            </Tooltip>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddPoolGroup}
                            size="small"
                        >
                            Add New Pool
                        </Button>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '80px' }}>Required</TableCell>
                                    <TableCell>Players in Pool</TableCell>
                                    <TableCell sx={{ width: '80px' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {persistentState.playerPoolGroups?.map((group) => (
                                    <TableRow key={group.id}>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                value={group.requiredCount}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value);
                                                    if (!isNaN(value) && value >= 1 && value <= group.players.length) {
                                                        handlePoolGroupChange(group.id, 'requiredCount', value);
                                                    }
                                                }}
                                                size="small"
                                                inputProps={{
                                                    min: 1,
                                                    max: group.players.length,
                                                    style: { width: '60px' }
                                                }}
                                                helperText={`of ${group.players.length}`}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                multiple
                                                value={players.filter(p =>
                                                    group.players.includes(p.Player)
                                                )}
                                                onChange={(_, newValue) => {
                                                    const newPlayers = newValue.map(p => p.Player);
                                                    handlePoolGroupChange(group.id, 'players', newPlayers);
                                                    // Adjust requiredCount if it's now greater than the number of players
                                                    if (group.requiredCount > newPlayers.length) {
                                                        handlePoolGroupChange(group.id, 'requiredCount', newPlayers.length);
                                                    }
                                                }}
                                                options={players}
                                                getOptionLabel={(player: Player) => player.Player}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((player, index) => (
                                                        <Chip
                                                            label={player.Player}
                                                            {...getTagProps({ index })}
                                                            size="small"
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        label="Select Players"
                                                        placeholder={group.players.length === 0 ? "Add players to pool..." : ""}
                                                    />
                                                )}
                                                sx={{ width: '100%' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleDeletePoolGroup(group.id)}
                                                size="small"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Grouping;