import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, CircularProgress, RadioGroup, FormControlLabel, Radio, Card, CardContent, Grid, Paper, Tooltip } from '@mui/material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useSlateContext } from '../context/SlateContext';
import { useAuth } from '../context/AuthContext';
import { useSubscription } from '../context/SubscriptionContext';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip as RechartsTooltip, ResponsiveContainer, Label, Cell } from 'recharts';
import { alpha } from '@mui/material/styles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';

interface StatsData {
    id: string;
    playerName?: string;
    Player?: string;
    teamAbbreviation?: string;
    'CashScore+': number;
    'UpsideScore+': number;
    'LeverageScore+': number;
    slate_identifier: number;
    isTopCash?: boolean;
    isTopUpside?: boolean;
    isTopLeverage?: boolean;
    Pos?: string
    position?: string;
    playerPosition?: string;
}

function PlayerStats() {
    const { isLoggedIn } = useAuth();
    const { slateId, sport, slateType } = useSlateContext();
    const [pitchers, setPitchers] = useState<StatsData[]>([]);
    const [players, setPlayers] = useState<StatsData[]>([]);
    const [stacks, setStacks] = useState<StatsData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedView, setSelectedView] = useState<'players' | 'stacks' | 'ALL' | 'PG' | 'SG' | 'SF' | 'PF' | 'C' | 'G' | 'F'>('players');
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' }>({ key: 'LeverageScore+', direction: 'desc' });
    const { userSubscription } = useSubscription();
    const [selectedPosition, setSelectedPosition] = useState<string>('ALL');

    useEffect(() => {
        if (isLoggedIn && slateId) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [isLoggedIn, slateId, sport, slateType]);

    const isFreeUser = useMemo(() => userSubscription?.subscriptionTier === 'free', [userSubscription]);


    const processData = (data: StatsData[]): StatsData[] => {
        // Filter out entries with '- CPT - CPT'
        const filteredData = data.filter(item =>
            !(item.Player?.includes('- CPT - CPT') ||
                item.playerName?.includes('- CPT - CPT') ||
                item.teamAbbreviation?.includes('- CPT - CPT'))
        );

        const sortedByCash = [...filteredData].sort((a, b) => b['CashScore+'] - a['CashScore+']);
        const sortedByUpside = [...filteredData].sort((a, b) => b['UpsideScore+'] - a['UpsideScore+']);
        const sortedByLeverage = [...filteredData].sort((a, b) => b['LeverageScore+'] - a['LeverageScore+']);

        const topCount = sport === 'NBA' ? 5 : 3;

        return filteredData.map(item => ({
            ...item,
            isTopCash: sortedByCash.indexOf(item) < topCount,
            isTopUpside: sortedByUpside.indexOf(item) < topCount,
            isTopLeverage: sortedByLeverage.indexOf(item) < topCount,
        }));
    };

    const LockedContentOverlay = () => (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
                gap: 2,
                backdropFilter: 'blur(.2px)'
            }}
        >
            <LockIcon sx={{ fontSize: 40, color: '#dc5f00' }} />
            <Typography variant="h6" color="text.secondary">
                Upgrade to unlock full Player Stats
            </Typography>
        </Box>
    );

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            console.log('Fetching data for:', { sport, slateId, slateType });
            let playersQuery, stacksQuery;

            if (slateType === 'Showdown') {
                playersQuery = query(
                    collection(db, 'pitchers'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
                stacksQuery = query(
                    collection(db, 'stacks'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
            } else if (sport === 'NFL') {
                playersQuery = query(
                    collection(db, 'player_stats'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
                stacksQuery = query(
                    collection(db, 'stacks'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
            } else if (sport === 'NBA') {
                playersQuery = query(
                    collection(db, 'player_stats'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport),
                );
                stacksQuery = null
            } else {
                playersQuery = query(
                    collection(db, 'pitchers'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
                stacksQuery = query(
                    collection(db, 'stacks'),
                    where('slate_identifier', '==', slateId),
                    where('sport', '==', sport)
                );
            }

            const [playersSnapshot, stacksSnapshot] = await Promise.all([
                getDocs(playersQuery),
                stacksQuery ? getDocs(stacksQuery) : Promise.resolve(null)
            ]);

            const roundToOneDecimal = (num: number) => Math.round(num * 10) / 10;

            const filterValidData = (data: any, isStack: boolean): boolean => {
                if (slateType === 'Showdown' || !isStack) {
                    // For Showdown slates or player data, we require the Player field
                    return (
                        (data.Player && data.Player.trim() !== '') &&
                        typeof data['CashScore+'] === 'number' &&
                        typeof data['UpsideScore+'] === 'number' &&
                        typeof data['LeverageScore+'] === 'number'
                    );
                } else {
                    // For Classic slate stacks, we check for teamAbbreviation instead of Player
                    return (
                        (data.teamAbbreviation && data.teamAbbreviation.trim() !== '') &&
                        typeof data['CashScore+'] === 'number' &&
                        typeof data['UpsideScore+'] === 'number' &&
                        typeof data['LeverageScore+'] === 'number'
                    );
                }
            };



            const playersData: StatsData[] = processData(playersSnapshot.docs
                .map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        playerName: data.playerName || data.Player,
                        Player: data.Player || data.playerName,
                        'CashScore+': roundToOneDecimal(data['CashScore+']),
                        'UpsideScore+': roundToOneDecimal(data['UpsideScore+']),
                        'LeverageScore+': roundToOneDecimal(data['LeverageScore+']),
                        slate_identifier: data.slate_identifier,
                        position: data.position,
                        Pos: data.Pos,
                    };
                })
                .filter(data => filterValidData(data, false)));

            setPlayers(playersData);

            if (stacksSnapshot) {
                const stacksData: StatsData[] = processData(stacksSnapshot.docs
                    .map(doc => {
                        const data = doc.data();
                        return {
                            id: doc.id,
                            playerName: data.Player || data.playerName,
                            Player: data.Player || data.playerName,
                            teamAbbreviation: data.teamAbbreviation,
                            'CashScore+': roundToOneDecimal(data['CashScore+']),
                            'UpsideScore+': roundToOneDecimal(data['UpsideScore+']),
                            'LeverageScore+': roundToOneDecimal(data['LeverageScore+']),
                            slate_identifier: data.slate_identifier,
                        };
                    })
                    .filter(data => filterValidData(data, true)));
                setStacks(stacksData);
            } else {
                setStacks([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('An error occurred while fetching data. Please try again later.');
        }
        setLoading(false);
    };

    const sortData = (data: StatsData[]) => {
        return [...data].sort((a, b) => {
            const key = sortConfig.key as keyof StatsData;
            if (key in a && key in b) {
                const aValue = a[key];
                const bValue = b[key];
                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
                }
            }
            return 0;
        });
    };

    const handleSort = (key: string) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const filteredPlayers = useMemo(() => {
        console.log('Filtering with:', { sport, selectedPosition });

        // Handle Showdown slate first
        if (slateType === 'Showdown') {
            return selectedPosition === 'players' ? players : stacks;
        }

        if (sport === 'NBA') {
            // Keep existing NBA logic as it works
            if (selectedPosition === 'ALL') {
                return players;
            }
            return players.filter(player => {
                const positions = player.Pos?.split('/') || [];
                return positions.some(pos => {
                    if (selectedPosition === 'G') {
                        return pos === 'PG' || pos === 'SG';
                    }
                    if (selectedPosition === 'F') {
                        return pos === 'SF' || pos === 'PF';
                    }
                    return pos === selectedPosition;
                });
            });
        }

        if (sport === 'NFL') {
            if (selectedPosition === 'STACKS') {
                return stacks;  // Don't need to map again since we already have the correct data structure
            }
            if (selectedPosition === 'ALL') {
                return players;
            }
            return players.filter(player => {
                return player.Pos === selectedPosition;
            });
        }

        // Default behavior for other sports
        return selectedView === 'players' ? players : stacks;
    }, [players, stacks, selectedPosition, sport, selectedView]); // Make sure all dependencies are listed

    useEffect(() => {
        if (sport === 'NFL') {
            console.log('State changed:', {
                selectedPosition,
                filteredCount: filteredPlayers.length,
                totalPlayers: players.length
            });
        }
    }, [selectedPosition, filteredPlayers, players, sport]);


    const getColorForScore = (score: number) => {
        if (score >= 110) return alpha('#4caf50', 0.2);   // Green with 20% opacity
        if (score >= 90) return alpha('#ffeb3b', 0.2);   // Yellow with 20% opacity
        return alpha('#f44336', 0.2);                     // Red with 20% opacity
    };

    const getColorForScorei = (score: number) => {
        if (score >= 110) return '#4caf50';   // Green with 20% opacity
        if (score >= 90) return '#ffeb3b';   // Yellow with 20% opacity
        return '#f44336';                     // Red with 20% opacity
    };

    useEffect(() => {
        setSelectedPosition('ALL');
    }, [sport]);

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: slateType === 'Showdown'
                ? (selectedView === 'players' ? 'FLEX' : 'Captain')
                : (selectedPosition === 'STACKS' ? 'Stack' : 'Player'), // Updated this line
            width: 200,
            renderCell: (params) => {
                // First check what kind of data we're dealing with based on the data itself
                const isStack = Boolean(params.row.teamAbbreviation); // Check if this is stack data

                // Log what we're working with
                console.log('Rendering cell:', {
                    isStack,
                    rowData: params.row,
                    teamAbbreviation: params.row.teamAbbreviation,
                    player: params.row.Player,
                    playerName: params.row.playerName
                });

                const name = slateType === 'Showdown'
                    ? params.row.Player || params.row.playerName || 'Unknown Player'
                    : isStack
                        ? params.row.teamAbbreviation || 'Unknown Stack'
                        : params.row.Player || params.row.playerName || 'Unknown Player';

                return (
                    <Box display="flex" alignItems="center">
                        <Typography>{name}</Typography>
                        {!isFreeUser && (
                            <Box ml={1} display="flex">
                                {params.row.isTopCash && <SecurityTwoToneIcon fontSize="small" sx={{ color: '#4caf50', mr: 0.5 }} />}
                                {params.row.isTopUpside && <RocketLaunchTwoToneIcon fontSize="small" sx={{ color: '#f44336', mr: 0.5 }} />}
                                {params.row.isTopLeverage && <EmojiEventsTwoToneIcon fontSize="small" sx={{ color: '#dc5f00' }} />}
                            </Box>
                        )}
                    </Box>
                );
            }
        },
        ...(sport === 'NBA' ? [
            {
                field: 'Pos',
                headerName: 'Position',
                width: 100,
            }
        ] : []),
        {
            field: 'CashScore+',
            headerName: 'Cash Score+',
            width: 130,
            renderHeader: () => (
                <Tooltip title="A measure of consistency for a particular player or stack, typical of the types of plays that cash game players favor.">
                    <Box display="flex" alignItems="center">
                        <SecurityTwoToneIcon fontSize="small" sx={{ mr: 0.5 }} />
                        Cash Score+
                    </Box>
                </Tooltip>
            ),
            renderCell: (params) => (
                <Box sx={{
                    backgroundColor: getColorForScore(params.value),
                    padding: '4px 8px',
                    borderRadius: '4px',
                    width: '100%',
                    textAlign: 'center'
                }}>
                    {params.value.toFixed(1)}
                </Box>
            ),
        },
        {
            field: 'UpsideScore+',
            headerName: 'Upside Score+',
            width: 130,
            renderHeader: () => (
                <Tooltip title="A measure of the potential upside for a particular player or stack. Useful for GPP players looking for high-ceiling options.">
                    <Box display="flex" alignItems="center">
                        <RocketLaunchTwoToneIcon fontSize="small" sx={{ mr: 0.5 }} />
                        Upside Score+
                    </Box>
                </Tooltip>
            ),
            renderCell: (params) => (
                <Box sx={{
                    backgroundColor: getColorForScore(params.value),
                    padding: '4px 8px',
                    borderRadius: '4px',
                    width: '100%',
                    textAlign: 'center'
                }}>
                    {params.value.toFixed(1)}
                </Box>
            ),
        },
        {
            field: 'LeverageScore+',
            headerName: 'Leverage Score+',
            width: 130,
            renderHeader: () => (
                <Tooltip title={isFreeUser
                    ? "Upgrade your subscription to access Leverage Score+"
                    : "A measure of consistency and upside while incorporating game theory. This aims to measure a player or stack's potential against their projected ownership."}>
                    <Box display="flex" alignItems="center">
                        <EmojiEventsTwoToneIcon fontSize="small" sx={{ mr: 0.5 }} />
                        Leverage Score+
                    </Box>
                </Tooltip>
            ),
            renderCell: (params) => (
                isFreeUser ? (
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                        <LockIcon sx={{ color: '#dc5f00' }} />
                    </Box>
                ) : (
                    <Box sx={{
                        backgroundColor: getColorForScore(params.value),
                        padding: '4px 8px',
                        borderRadius: '4px',
                        width: '100%',
                        textAlign: 'center'
                    }}>
                        {params.value.toFixed(1)}
                    </Box>
                )
            ),
        },
    ], [selectedView, sport, slateType, isFreeUser]);

    useEffect(() => {
        console.log('Current state:', {
            sport,
            selectedPosition,
            playersCount: players.length,
            filteredPlayersCount: filteredPlayers.length
        });
    }, [sport, selectedPosition, players, filteredPlayers]);

    const renderBubbleChart = (data: StatsData[]) => {
        const dataToUse = (sport === 'NBA' || sport === 'NFL') ? filteredPlayers : data;
        const scaledData = filteredPlayers.map(d => ({
            ...d,
            scaledLeverageScore: isFreeUser ? 1000 : (d['LeverageScore+'] - 80) * 5,
            displayName: sport === 'NFL' && selectedPosition === 'STACKS'
                ? d.teamAbbreviation
                : (d.playerName || d.Player || 'Unknown')
        }));

        const colors = ['#2E4053', '#566573', '#1ABC9C', '#2980B9', '#8E44AD', '#C0392B', '#D35400', '#27AE60', '#F39C12', '#34495E', '#16A085', '#2980B9', '#E74C3C', '#F1C40F', '#E67E22', '#B03A2E'];

        return (
            <ResponsiveContainer width="100%" height={550}>
                <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                    <XAxis
                        type="number"
                        dataKey="CashScore+"
                        name="Cash Score+"
                        unit="+"
                        domain={[60, 140]}
                    >
                        <Label value="Cash Score+" position="insideBottom" offset={-10} />
                    </XAxis>
                    <YAxis
                        type="number"
                        dataKey="UpsideScore+"
                        name="Upside Score+"
                        unit="+"
                        domain={[60, 140]}
                    >
                        <Label value="Upside Score+" angle={-90} position="insideLeft" offset={10} />
                    </YAxis>
                    <ZAxis type="number" dataKey="scaledLeverageScore" range={[50, 2000]} />
                    <RechartsTooltip
                        cursor={{ strokeDasharray: '3 3' }}
                        content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                                const { displayName, 'CashScore+': cashScore, 'UpsideScore+': upsideScore, 'LeverageScore+': leverageScore } = payload[0].payload;

                                return (
                                    <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
                                        <p>{`${sport === 'NFL' && selectedPosition === 'STACKS' ? 'Stack' : 'Player'}: ${displayName}`}</p>
                                        <p>{`Cash Score+: ${cashScore}`}</p>
                                        <p>{`Upside Score+: ${upsideScore}`}</p>
                                        {!isFreeUser && <p>{`Leverage Score+: ${leverageScore}`}</p>}
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                    <Scatter data={scaledData} opacity={0.7}>
                        {scaledData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Scatter>
                </ScatterChart>
            </ResponsiveContainer>
        );
    };

    if (!isLoggedIn) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 64px)',
                p: 3,
                textAlign: 'center'
            }}>
                <Typography variant="h4" gutterBottom>
                    Welcome to the DFS OS
                </Typography>
                <Typography variant="h5" color="error" sx={{ mb: 2 }}>
                    You need to be logged in to use this feature
                </Typography>
                <Typography variant="body1">
                    Please use the login button in the top right corner to access all features.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                    If you don't have an account yet, you can sign up for free!
                </Typography>
            </Box>
        );
    }

    if (!slateId) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h5">Please select a Slate to view Data.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 1, maxWidth: '100%', overflow: 'hidden', marginTop: 8 }}>
            <Card elevation={3} sx={{ mb: 1 }}>
                <CardContent sx={{ py: 1 }}>
                    <RadioGroup
                        row
                        value={selectedPosition}
                        onChange={(e) => {
                            if (!isFreeUser) {
                                const newValue = e.target.value as string;
                                setSelectedPosition(newValue);
                            }
                        }}
                        sx={{
                            opacity: isFreeUser ? 0.7 : 1,
                            pointerEvents: isFreeUser ? 'none' : 'auto'
                        }}
                    >
                        {slateType === 'Showdown' ? (
                            // Showdown radio options
                            <>
                                <FormControlLabel
                                    value="players"
                                    control={<Radio />}
                                    label="FLEX"
                                />
                                <FormControlLabel
                                    value="stacks"
                                    control={<Radio />}
                                    label="Captains"
                                />
                            </>
                        ) : sport === 'NBA' ? (
                            // NBA options
                            <>
                                <FormControlLabel value="ALL" control={<Radio />} label="ALL" />
                                <FormControlLabel value="PG" control={<Radio />} label="PG" />
                                <FormControlLabel value="SG" control={<Radio />} label="SG" />
                                <FormControlLabel value="SF" control={<Radio />} label="SF" />
                                <FormControlLabel value="PF" control={<Radio />} label="PF" />
                                <FormControlLabel value="C" control={<Radio />} label="C" />
                                <FormControlLabel value="G" control={<Radio />} label="G" />
                                <FormControlLabel value="F" control={<Radio />} label="F" />
                            </>
                        ) : sport === 'NFL' && slateType === 'Classic' ? (  // Changed this line
                            // Classic NFL options
                            <>
                                <FormControlLabel value="ALL" control={<Radio />} label="ALL" />
                                <FormControlLabel value="QB" control={<Radio />} label="QB" />
                                <FormControlLabel value="RB" control={<Radio />} label="RB" />
                                <FormControlLabel value="WR" control={<Radio />} label="WR" />
                                <FormControlLabel value="TE" control={<Radio />} label="TE" />
                                <FormControlLabel value="STACKS" control={<Radio />} label="Stacks" />
                            </>
                        ) : (
                            // Default options for other sports
                            <>
                                <FormControlLabel
                                    value="players"
                                    control={<Radio />}
                                    label={slateType !== 'Classic' ? 'FLEX' : (sport === 'NFL' ? 'QBs' : 'Pitchers')}
                                />
                                <FormControlLabel
                                    value="stacks"
                                    control={<Radio />}
                                    label={slateType !== 'Classic' ? 'Captains' : 'Stacks'}
                                />
                            </>
                        )}
                    </RadioGroup>
                    {isFreeUser && (
                        <Tooltip title="Upgrade to access detailed player and team analysis">
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'not-allowed'
                                }}
                            >
                                <LockIcon sx={{ color: '#dc5f00' }} />
                            </Box>
                        </Tooltip>
                    )}
                </CardContent>
            </Card>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={5}>
                        <Paper elevation={3} sx={{ height: 600, width: '100%' }}>
                            <DataGrid
                                rows={filteredPlayers}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 50, page: 0 },
                                    },
                                }}
                                rowHeight={50}
                                pageSizeOptions={[50, 75, 100]}
                                density="compact"
                                sx={{
                                    opacity: isFreeUser ? 0.7 : 1,
                                    pointerEvents: isFreeUser ? 'none' : 'auto'
                                }}
                            />
                            {isFreeUser && <LockedContentOverlay />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Paper elevation={3} sx={{ height: 600, width: '100%' }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Score Distribution</Typography>
                                {renderBubbleChart(selectedView === 'players' ? players : stacks)}
                            </CardContent>
                            {isFreeUser && <LockedContentOverlay />}
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

export default PlayerStats;