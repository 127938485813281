import React, { useState, useEffect, useMemo } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO, isValid, isBefore } from 'date-fns';
import { useAuth } from '../context/AuthContext';
import SportsFootballTwoToneIcon from '@mui/icons-material/SportsFootballTwoTone';
import SportsBaseballTwoToneIcon from '@mui/icons-material/SportsBaseballTwoTone';
import SportsBasketballTwoToneIcon from '@mui/icons-material/SportsBasketballTwoTone';
import GolfCourseTwoToneIcon from '@mui/icons-material/GolfCourseTwoTone';
import { usePersistentState } from '../context/PersistentStateContext';

interface Slate {
    id: string;
    forDate: string;
    slate_identifier: number;
    type: string;
    sport: string;
}

interface Game {
    awayTeamAbbreviation: string;
    homeTeamAbbreviation: string;
    startTime: string;
    slate_identifier: number;
}

interface SlateFiltersProps {
    onFilterChange: (site: string, sport: string, date: string | null, slateId: number | null, slateType: 'Classic' | 'Showdown' | null) => void;
}

const SlateFilters: React.FC<SlateFiltersProps> = ({ onFilterChange }) => {
    const [sport, setSport] = useState('MLB');
    const [date, setDate] = useState<Date | null>(null);
    const [slateId, setSlateId] = useState<number | null>(null);
    const [slateType, setSlateType] = useState<string>('Classic');
    const [availableDates, setAvailableDates] = useState<Date[]>([]);
    const [availableSlates, setAvailableSlates] = useState<Slate[]>([]);
    const [availableSports, setAvailableSports] = useState<string[]>([]);
    const [games, setGames] = useState<Game[]>([]);
    const minSelectableDate = new Date('2024-09-03');
    const { isLoggedIn, isLoading } = useAuth();
    const [site, setSite] = useState('DraftKings');
    const { updatePersistentState, resetStateForNewSlate } = usePersistentState();


    const sportIcons = {
        NFL: <SportsFootballTwoToneIcon />,
        MLB: <SportsBaseballTwoToneIcon />,
        NBA: <SportsBasketballTwoToneIcon />,
        PGA: <GolfCourseTwoToneIcon />,
    };

    useEffect(() => {
        if (isLoggedIn && !isLoading) {
            fetchAvailableSports(site);
        }
    }, [isLoggedIn, isLoading]);

    useEffect(() => {
        if (sport) {
            fetchAvailableDates(site, sport);
        }
    }, [site, sport]);

    useEffect(() => {
        if (sport && date) {
            fetchAvailableSlates(site, sport, date);
        }
    }, [site, sport, date]);

    useEffect(() => {
        if (site === 'FanDuel' && sport === 'MLB') {
            setSport('');
            setDate(null);
            setSlateId(null);
            onFilterChange(site, '', null, null, 'Classic');
        }
    }, [site, sport]);

    // const handleSiteChange = (event: SelectChangeEvent<string>) => {
    //     setSite(event.target.value);
    // };

    const handleSiteChange = (event: SelectChangeEvent<string>) => {
        const newSite = event.target.value;
        setSite(newSite);
        setSport('');
        setDate(null);
        setSlateId(null);
        setSlateType('Classic');
        onFilterChange(newSite, '', null, null, 'Classic');
    };


    const fetchAvailableSports = async (selectedSite: string) => {
        if (!isLoggedIn) return;
        const slatesCollection = collection(db, 'slates');
        const siteQuery = query(slatesCollection, where('site', '==', selectedSite));
        const slatesSnapshot = await getDocs(siteQuery);
        const sports = new Set<string>();
        slatesSnapshot.forEach(doc => {
            const sportField = doc.data().sport;
            if (sportField) sports.add(sportField);
        });
        setAvailableSports(Array.from(sports));
    };

    const fetchAvailableDates = async (selectedSite: string, selectedSport: string) => {
        if (!isLoggedIn) return;
        const slatesCollection = collection(db, 'slates');
        const siteAndSportQuery = query(slatesCollection,
            where('site', '==', selectedSite),
            where('sport', '==', selectedSport)
        );
        const slatesSnapshot = await getDocs(siteAndSportQuery);
        const dates = slatesSnapshot.docs
            .map(doc => {
                const forDate = doc.data().forDate;
                const parsedDate = parseISO(forDate);
                return isValid(parsedDate) ? parsedDate : null;
            })
            .filter((date): date is Date => date !== null);

        setAvailableDates(Array.from(new Set(dates)).sort((a, b) => a.getTime() - b.getTime()));
    };

    const fetchAvailableSlates = async (selectedSite: string, selectedSport: string, selectedDate: Date) => {
        if (!isLoggedIn) return;
        const slatesCollection = collection(db, 'slates');
        const q = query(
            slatesCollection,
            where('site', '==', selectedSite),
            where('sport', '==', selectedSport),
            where('forDate', '==', selectedDate.toISOString().split('T')[0])
        );
        const slatesSnapshot = await getDocs(q);
        const slates = slatesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        } as Slate)).filter(slate => slate.type && typeof slate.type === 'string');
        setAvailableSlates(slates);

        console.log(`Fetched slates for ${selectedSite}, ${selectedSport}, ${selectedDate.toISOString().split('T')[0]}:`, slates);
    };

    const handleFilterChange = (sport: string, date: string | null, slateId: number | null, slateType: 'Classic' | 'Showdown' | null) => {
        // Reset edited players when a new slate is selected
        updatePersistentState({ editedPlayers: {} });

        // Call the original onFilterChange
        onFilterChange(site, sport, date, slateId, slateType);
    };



    const fetchGames = async (slateIdentifier: number): Promise<Game[]> => {
        const gamesCollection = collection(db, 'slates_games');
        const q = query(gamesCollection, where('slate_identifier', '==', slateIdentifier));
        const gamesSnapshot = await getDocs(q);
        return gamesSnapshot.docs.map(doc => doc.data() as Game);
    };

    const handleSportChange = (event: SelectChangeEvent<string>) => {
        const newSport = event.target.value;
        setSport(newSport);
        setDate(null);
        setSlateId(null);
        setSlateType('Classic');
        onFilterChange(site, newSport, null, null, 'Classic');
    };

    const handleDateChange = (newDate: Date | null) => {
        setDate(newDate);
        setSlateId(null);
        setSlateType('Classic');
        onFilterChange(site, sport, newDate ? newDate.toISOString().split('T')[0] : null, null, 'Classic');
    };

    const handleSlateChange = (event: SelectChangeEvent<number>) => {
        const newSlateId = event.target.value as number;
        const selectedSlate = availableSlates.find(slate => slate.slate_identifier === newSlateId);
        let newSlateType: string = 'Classic'; // Default to 'Classic'

        if (selectedSlate) {
            if (site === 'DraftKings') {
                newSlateType = selectedSlate.type.startsWith('Showdown') ? 'Showdown' : 'Classic';
            } else if (site === 'FanDuel') {
                newSlateType = selectedSlate.type.includes('Single Game') ? 'Showdown' : 'Classic';
            }
        }

        setSlateId(newSlateId);
        setSlateType(newSlateType);

        // Reset state for new slate
        resetStateForNewSlate();

        // Update persistent state with new slate information
        updatePersistentState({
            slateId: newSlateId,
            slateType: newSlateType as 'Classic' | 'Showdown',
            site,
            sport,
            date: date ? date.toISOString().split('T')[0] : null,
        });

        onFilterChange(site, sport, date ? date.toISOString().split('T')[0] : null, newSlateId, newSlateType as 'Classic' | 'Showdown');
    };

    const formatTime = (timeString: string) => {
        const date = new Date(timeString);
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'America/New_York'
        });
    };

    const getSlateDisplayText = (slate: Slate) => {
        if (slate.type.startsWith('Showdown')) {
            const game = games.find(g => g.slate_identifier === slate.slate_identifier);
            if (game) {
                return `${slate.type} - ${game.awayTeamAbbreviation} @ ${game.homeTeamAbbreviation}`;
            }
        }
        return slate.type;
    };


    const filteredAndSortedSlates = useMemo(() => {
        return availableSlates
            .filter(slate => {
                if (site === 'DraftKings') {
                    return slate.type.startsWith('Classic') || slate.type.startsWith('Showdown');
                } else if (site === 'FanDuel') {
                    return slate.type.startsWith('Full Roster') || slate.type.includes('Single Game');
                }
                return false;
            })
            .sort((a, b) => a.type.localeCompare(b.type));
    }, [availableSlates, site]);

    const allSports = ['MLB', 'NFL', 'NBA', 'PGA'];

    return (
        <Box display="flex" alignItems="center" gap={2}>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 180 }}>
                <InputLabel sx={{ color: 'white', fontSize: '0.9rem' }} id="site-label">Site</InputLabel>
                <Select
                    labelId="site-label"
                    value={site}
                    onChange={handleSiteChange}
                    label="Site"
                    sx={{
                        color: 'white', '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, fontSize: '0.9rem', '& .MuiSelect-icon': {
                            color: 'white', // Arrow color
                        },
                    }}
                >
                    <MenuItem value="DraftKings">DraftKings</MenuItem>
                    <MenuItem value="FanDuel">FanDuel</MenuItem>
                </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 80 }}>
                <InputLabel sx={{ color: 'white', fontSize: '0.9rem' }}>Sport</InputLabel>
                <Select
                    value={sport}
                    onChange={handleSportChange}
                    label="Sport"
                    sx={{
                        color: 'white', '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, fontSize: '0.9rem', '& .MuiSelect-icon': {
                            color: 'white', // Arrow color
                        },
                    }}
                >
                    {allSports.map((sportOption) => (
                        <MenuItem
                            key={sportOption}
                            value={sportOption}
                            disabled={!availableSports.includes(sportOption) || (site === 'FanDuel' && sportOption === 'MLB')}
                            sx={{
                                color: availableSports.includes(sportOption) && !(site === 'FanDuel' && sportOption === 'MLB') ? 'inherit' : 'text.disabled',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {sportIcons[sportOption as keyof typeof sportIcons]}
                                <Box component="span" sx={{ ml: 1 }}>{sportOption}</Box>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Date"
                    value={date}
                    onChange={handleDateChange}
                    shouldDisableDate={(date) => {
                        if (isBefore(date, minSelectableDate)) {
                            return true;
                        }
                        const formattedDate = format(date, 'yyyy-MM-dd');
                        return !availableDates.some(availableDate =>
                            format(availableDate, 'yyyy-MM-dd') === formattedDate
                        );
                    }}
                    minDate={minSelectableDate}
                    slotProps={{
                        textField: {
                            size: "small",
                            sx: {
                                minWidth: 100,
                                input: { color: 'white' },
                                label: { color: 'white' },
                                '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                                '& .MuiOutlinedInput-root': {
                                    color: 'white', // Text color
                                    '& fieldset': {
                                        borderColor: 'white', // Border color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white', // Hover border color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white', // Focused border color
                                    },
                                },
                                '& .MuiInputAdornment-root svg': {
                                    color: 'white', // Icon color (calendar icon)
                                },
                            }
                        }
                    }}
                />
            </LocalizationProvider>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 100 }}>
                <InputLabel sx={{ color: 'white', fontSize: '0.9rem' }}>Slate</InputLabel>
                <Select
                    value={slateId || ''}
                    onChange={handleSlateChange}
                    label="Slate"
                    disabled={!date}
                    sx={{
                        color: 'white', '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, fontSize: '.9rem', '& .MuiSelect-icon': {
                            color: 'white', // Arrow color
                        },
                    }}
                >
                    {filteredAndSortedSlates
                        .filter(slate => sport === 'NFL' || !slate.type.startsWith('Showdown'))
                        .map((slate) => (
                            <MenuItem key={slate.id} value={slate.slate_identifier}>
                                {slate.type}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default SlateFilters;