import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import HandymanIcon from '@mui/icons-material/Handyman';
import StadiumIcon from '@mui/icons-material/Stadium';
import HelpIcon from '@mui/icons-material/Help';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const drawerWidth = 220;

interface SidebarItem {
    text: string;
    icon: React.ReactElement;
    path: string;
}

const sidebarItems: SidebarItem[] = [
    { text: 'Player Stats & Stacks', icon: <AutoGraphIcon />, path: '/player-stats-and-stacks' },
    { text: 'Lineup Optimizer', icon: <HandymanIcon />, path: '/lineup-optimizer' },
    { text: 'Contest Simulator', icon: <StadiumIcon />, path: '/contest-simulator' },
    { text: 'Help', icon: <HelpIcon />, path: '/documentation' },
    { text: 'Profile', icon: <ManageAccountsIcon />, path: '/subscription' },
];

function Sidebar() {
    const { isLoggedIn } = useAuth();
    const location = useLocation();

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <List sx={{ mt: 6 }}>
                {sidebarItems.map((item) => (
                    <ListItemButton
                        key={item.text}
                        component={Link}
                        to={item.path}
                        disabled={!isLoggedIn}
                        selected={location.pathname === item.path}
                        sx={{
                            color: 'inherit',
                            '&.Mui-disabled': {
                                opacity: 0.5,
                            },
                            '&.Mui-selected': {
                                backgroundColor: 'action.selected',
                            },
                            '& .MuiListItemIcon-root': {
                                minWidth: 40,
                            },
                        }}
                    >
                        <ListItemIcon sx={{ color: '#dc5f00' }}>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItemButton>
                ))}
            </List>
        </Drawer>
    );
}

export default Sidebar;