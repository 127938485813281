import React, { useState, Dispatch, useEffect, SetStateAction, useMemo, useRef, useCallback } from 'react';
import { Box, Chip, TextField, Typography, Paper, Tooltip, Button, Grid, Alert, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DataGrid, GridColDef, GridRowModel, GridValueGetter, GridCellParams, GridPreProcessEditCellProps, GridCellEditStopParams, GridRowSelectionModel } from '@mui/x-data-grid';
import {
    collection, getDocs, query, where, orderBy, onSnapshot,
    QuerySnapshot,
    DocumentChange, addDoc, serverTimestamp
} from 'firebase/firestore';
import { db } from '../../firebase';
import GameDisplay from './Game';
import { useSlateContext } from '../../context/SlateContext';
import { useAuth } from '../../context/AuthContext';
// import { useSettings } from '../../context/SettingsContext';
import { getAuth } from 'firebase/auth';
import LineupResults from '../LineupResults';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import LineupTable from './LineupTable';
import PlayerExposureTable from './PlayerExposureTable';
import { useTheme, useMediaQuery } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { usePersistentState, PersistentState } from '../../context/PersistentStateContext';
import { NFLTeamStack, StackRule, Game, CustomProjection, Player } from '../../types';
import { FaCrown } from 'react-icons/fa';
import { useSubscription } from '../../context/SubscriptionContext';
import LockIcon from '@mui/icons-material/Lock';
import FlareTwoToneIcon from '@mui/icons-material/FlareTwoTone';
import Papa from 'papaparse';
import { useLineups } from '../../context/LineupContext';
import { BaseLineup } from '../../types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



interface PlayerExposure {
    id: string;
    name: string;
    position: string;
    team: string;
    count: number;
}

interface LineupPlayer {
    playerId: string;
    position: string;
    projection: number;
    salary: number;
}

interface ProcessedLineup {
    QB: LineupPlayer[];
    RB: LineupPlayer[];
    WR: LineupPlayer[];
    TE: LineupPlayer[];
    FLEX: LineupPlayer[];
    DST: LineupPlayer[];
    [key: string]: LineupPlayer[]; // Index signature
}



interface StackingInfo {
    primaryStacks: string[];
    secondaryStacks: string[];
    usePrimaryStacks: boolean;
    useSecondaryStacks: boolean;
    primaryStackCount: number | null;
    secondaryStackCount: number | null;
}



interface Lineup {
    lineupId: string;
    totalProjection: number;
    totalSalary: number;
    players: LineupPlayer[];
}



interface PlayerPoolProps {
    onPlayersUpdate: (players: Player[]) => void;
    showdownStackRules: StackRule[];
    numberOfLineups: number;
    stackingInfo: {
        primaryStacks: string[];
        secondaryStacks: string[];
        usePrimaryStacks: boolean;
        useSecondaryStacks: boolean;
        primaryStackCount: number | null;
        secondaryStackCount: number | null;
        nflTeamStacks: NFLTeamStack[];
    };
    isShowdown: boolean;
    updatePersistentState: (updates: Partial<PersistentState>) => void;
}



interface UnmatchedPlayersDialogProps {
    open: boolean;
    onClose: () => void;
    unmatchedPlayers: string[];
}



const PlayerPool: React.FC<PlayerPoolProps> = React.memo(({ onPlayersUpdate, showdownStackRules }) => {
    const { persistentState, updatePersistentState } = usePersistentState();
    const { isLoggedIn, user } = useAuth();
    const { site, sport, date, slateId, slateType: rawSlateType } = useSlateContext();
    const slateType = rawSlateType ? (rawSlateType.startsWith('Showdown') ? 'Showdown' : 'Classic') : null;
    // const { settings } = useSettings();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isShowdown = slateType === 'Showdown';
    const { userSubscription } = useSubscription();
    const lineupTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const renderCount = useRef(0);
    const lastPlayersJSON = useRef('');
    // const [players, setPlayers] = useState(persistentState.players || []);
    const {
        numberOfLineups,
        // players,
        minProj,
        minOwn,
        maxOwn,
        minValue,
        selectedTeams,
        searchTerm,
        editedPlayers,
        selectedPlayerIds,
        lineups,
        playerExposures,
        isOptimizerStarting,
        warnings,
        usingCustomProjections,
    } = persistentState;


    useEffect(() => {
        renderCount.current += 1;
        console.log(`PlayerPool rendered ${renderCount.current} times`);
    });

    console.log('PlayerPool rendering', {
        playersLength: persistentState.players.length,
        selectedPositionsLength: persistentState.selectedPositions.length,
        selectedTeamsLength: persistentState.selectedTeams.length,
        searchTerm: persistentState.searchTerm,
        minProj: persistentState.minProj,
        minValue: persistentState.minValue
    });


    const getMaxLineups = () => {
        switch (userSubscription?.subscriptionTier) {
            case 'rookie':
                return 5000;
            case 'mvp':
                return 5000;
            default:
                return 1;
        }
    };

    const processCSVUpload = (file: File): Promise<CustomProjection[]> => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                complete: (results) => {
                    const headers = results.meta.fields || [];
                    const playerIndex = headers.findIndex(h => h === 'Player');
                    const projIndex = headers.findIndex(h => h === 'Proj');
                    const ownIndex = headers.findIndex(h => h === 'Own');
                    const olimitIndex = headers.findIndex(h => h === 'Olimit');
                    const stdDevIndex = headers.findIndex(h => h === 'StdDev');

                    if (playerIndex === -1) {
                        reject(new Error('CSV must contain a "Player" column'));
                        return;
                    }

                    const customProjections: CustomProjection[] = results.data
                        .filter((row: any) => row[headers[playerIndex]])
                        .map((row: any) => {
                            const projection: CustomProjection = {
                                Player: row[headers[playerIndex]]
                            };

                            if (projIndex !== -1 && row[headers[projIndex]]) {
                                projection.Proj = parseFloat(row[headers[projIndex]]);
                            }
                            if (ownIndex !== -1 && row[headers[ownIndex]]) {
                                projection.Own = parseFloat(row[headers[ownIndex]]);
                            }
                            if (olimitIndex !== -1 && row[headers[olimitIndex]]) {
                                projection.Olimit = parseFloat(row[headers[olimitIndex]]);
                            }
                            if (stdDevIndex !== -1 && row[headers[stdDevIndex]]) {
                                projection.StdDev = parseFloat(row[headers[stdDevIndex]]); // Add this block
                            }

                            return projection;
                        });

                    resolve(customProjections);
                },
                header: true,
                error: (error) => {
                    reject(error);
                }
            });
        });
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                updatePersistentState({ isLoading: true });
                const projections = await processCSVUpload(file);
                const result = mergePlayers(projections);
                updatePersistentState({ isLoading: false });

                if (result.updatedCount === 0 && result.unmatchedCount === 0) {
                    alert('No changes were made. The uploaded data matches the existing data or no updateable fields were found in the CSV.');
                } else {
                    const message = `Updated ${result.updatedCount} players. ${result.unmatchedCount} players could not be matched.`;
                    alert(message);
                }

                updatePersistentState({
                    players: result.updatedPlayers,
                    editedPlayers: result.updatedEditedPlayers, // Add this line
                    usingCustomProjections: result.updatedCount > 0
                });
            } catch (error) {
                updatePersistentState({ isLoading: false });
                console.error('Error processing CSV:', error);
                if (error instanceof Error) {
                    alert(`Error processing CSV: ${error.message}`);
                } else {
                    alert('Error processing CSV. Please check the file format.');
                }
            }
        }
    };


    const mergePlayers = (customProjections: CustomProjection[]) => {
        let updatedCount = 0;
        let unmatchedCount = 0;
        const updatedEditedPlayers = { ...persistentState.editedPlayers };

        const updatedPlayers = persistentState.players.map(player => {
            const customProjection = customProjections.find(cp => cp.Player === player.Player);
            if (customProjection) {
                let isUpdated = false;
                const updates: Partial<Player> = {};
                const editedFields: Partial<Player> = updatedEditedPlayers[player.id] || {};

                if (customProjection.Proj !== undefined) {
                    const projChanged = Math.abs(customProjection.Proj - player.Proj) > 0.01;
                    if (projChanged) {
                        updates.Proj = customProjection.Proj;
                        editedFields.Proj = customProjection.Proj;
                        isUpdated = true;
                    }
                }

                if (customProjection.Own !== undefined) {
                    const ownChanged = Math.abs(customProjection.Own - player.Own) > 0.01;
                    if (ownChanged) {
                        updates.Own = customProjection.Own;
                        editedFields.Own = customProjection.Own;
                        isUpdated = true;
                    }
                }

                if (customProjection.Olimit !== undefined) {
                    const currentOlimit = player.Olimit ?? 100;
                    const olimitChanged = Math.abs(customProjection.Olimit - currentOlimit) > 0.01;
                    if (olimitChanged) {
                        updates.Olimit = customProjection.Olimit;
                        editedFields.Olimit = customProjection.Olimit;
                        isUpdated = true;
                    }
                }

                if (customProjection.StdDev !== undefined) {
                    const stdDevChanged = Math.abs(customProjection.StdDev - player.StdDev) > 0.01;
                    if (stdDevChanged) {
                        updates.StdDev = customProjection.StdDev;
                        editedFields.StdDev = customProjection.StdDev;
                        isUpdated = true;
                    }
                }

                if (isUpdated) {
                    updatedCount++;
                    updatedEditedPlayers[player.id] = editedFields;
                    return { ...player, ...updates };
                }
            }
            return player;
        });

        unmatchedCount = customProjections.filter(cp =>
            !persistentState.players.some(player => player.Player === cp.Player)
        ).length;

        const usingCustomProjections = updatedCount > 0;

        return {
            updatedPlayers,
            updatedEditedPlayers,
            updatedCount,
            unmatchedCount,
            usingCustomProjections
        };
    };



    const editableCellStyle = {
        backgroundColor: '#f0f0f0',
        border: '1px solid #000',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    };

    const fetchGamesAndPlayers = useCallback(async () => {
        try {
            if (!slateId) return;

            // Fetch games
            const gamesQuery = query(collection(db, 'slates_games'), where('slate_identifier', '==', slateId), where('site', '==', site));
            const gameSnapshot = await getDocs(gamesQuery);
            const gameList = gameSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as Game));

            // Fetch players
            let playersQuery = query(collection(db, 'data_players'), where('slate_identifier', '==', slateId), where('site', '==', site));


            if (sport === 'NFL') {
                playersQuery = query(playersQuery,
                    where('position', 'in', [
                        'Offense-QB-1',
                        'Offense-RB-1', 'Offense-RB-2', 'Offense-RB-3',
                        'Offense-WR-1', 'Offense-WR-2', 'Offense-WR-3', 'Offense-WR-4',
                        'Offense-TE-1', 'Offense-TE-2',
                        'Defense-DST-1', 'SpecialTeams-K-1', 'Offense-FB-1', 'Offense-FB-2', 'Offense-D-1', 'Offense-D-2'
                    ])
                );
            }

            const playerSnapshot = await getDocs(playersQuery);
            const fetchedPlayers = playerSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    slate_identifier: slateId,
                    Pos: slateType === 'Showdown' ? (data.Player.includes('CPT') ? 'CPT' : 'FLEX') : (data.playerPosition || data.Pos),
                    Team: data.teamAbbreviation || data.Team,
                    Salary: data.salary || data.Salary,
                    Opp: data.opponent?.replace(/^(vs |@ )/, '') || data.Opp,
                    Player: data.Player,
                    // Include other necessary fields
                } as Player;
            });

            // **Merge fetchedPlayers with existing edits**
            const mergedPlayers = fetchedPlayers.map(player => {
                const editedFields = persistentState.editedPlayers[player.id] || {};
                return {
                    ...player,
                    ...editedFields,
                    Olimit: editedFields.Olimit ?? (player.Olimit || 100),
                    Own: editedFields.Own ?? player.Own,
                    Proj: editedFields.Proj ?? player.Proj,
                    StdDev: editedFields.StdDev ?? player.StdDev,
                };
            });

            // Set teams based on the games
            const allTeams = new Set(gameList.flatMap(game => [game.homeTeamAbbreviation, game.awayTeamAbbreviation]));

            // Update persistent state with merged players
            updatePersistentState(prevState => ({
                ...prevState,
                games: gameList,
                players: mergedPlayers,
                selectedTeams: Array.from(allTeams),
            }));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [slateId, sport, slateType, site, updatePersistentState]);

    const getNBAPositions = (positionString: string): string[] => {
        const positions = positionString.split('/');
        const expandedPositions = new Set<string>();

        positions.forEach(pos => {
            expandedPositions.add(pos);
            // Add composite positions
            if (pos === 'PG' || pos === 'SG') expandedPositions.add('G');
            if (pos === 'SF' || pos === 'PF') expandedPositions.add('F');
        });

        return Array.from(expandedPositions);
    };



    const allPlayers = useMemo(() => {
        const filteredPlayers = persistentState.players.filter(player => {
            // Position filtering logic
            const positionMatch = isShowdown || (() => {
                // If no positions selected, show all players
                if (persistentState.selectedPositions.length === 0) {
                    return true;
                }

                if (sport === 'NBA') {
                    const playerPositions = getNBAPositions(player.Pos);
                    return persistentState.selectedPositions.some(selectedPos =>
                        playerPositions.includes(selectedPos)
                    );
                }

                return persistentState.selectedPositions.includes(player.Pos);
            })();

            // Rest of the filtering logic remains the same
            const teamMatch = persistentState.selectedTeams.includes(sport === 'NFL' ? player.teamAbbreviation || '' : player.Team || '');
            const searchMatch = player.Player.toLowerCase().includes((persistentState.searchTerm || '').toLowerCase()) ||
                (sport === 'NFL' ? player.teamAbbreviation : player.Team)?.toLowerCase().includes((persistentState.searchTerm || '').toLowerCase());
            const projMatch = persistentState.minProj === '' || player.Proj >= persistentState.minProj;
            const ownMatch = (persistentState.minOwn === '' || player.Own >= persistentState.minOwn) &&
                (persistentState.maxOwn === '' || player.Own <= persistentState.maxOwn);
            const valueMatch = persistentState.minValue === '' || (player.Proj / player.Salary) * 1000 >= persistentState.minValue;

            return positionMatch && teamMatch && searchMatch && projMatch && ownMatch && valueMatch;
        });

        return filteredPlayers;
    }, [persistentState.players, persistentState.selectedPositions, persistentState.selectedTeams, persistentState.searchTerm,
    persistentState.minProj, persistentState.minOwn, persistentState.maxOwn, persistentState.minValue, sport, isShowdown]);

    const initialSelectionRef = useRef(false);

    const prevSportRef = useRef(sport);
    const prevSlateIdRef = useRef(slateId);


    useEffect(() => {
        if (sport && slateId) {
            const sportChanged = sport !== prevSportRef.current;
            const slateIdChanged = slateId !== prevSlateIdRef.current;

            if (sportChanged || slateIdChanged) {
                updatePersistentState({
                    lineups: [],
                    players: [],
                    selectedPlayerIds: [],
                    editedPlayers: {},
                    lineupProgress: 0,
                    totalLineups: 0,
                    playerExposures: {},
                    isOptimizerStarting: false,
                    warnings: []
                });
                prevSportRef.current = sport;
                prevSlateIdRef.current = slateId;
                fetchGamesAndPlayers();
            }
        }
    }, [sport, slateId, fetchGamesAndPlayers, updatePersistentState]);


    useEffect(() => {
        if (persistentState.players.length > 0) {
            const playersJSON = JSON.stringify(persistentState.players);
            if (playersJSON !== lastPlayersJSON.current) {
                onPlayersUpdate(persistentState.players);
                lastPlayersJSON.current = playersJSON;
            }
        }
    }, [persistentState.players, onPlayersUpdate]);



    useEffect(() => {
        if (isLoggedIn && slateId) {
            fetchGamesAndPlayers();
        }
    }, [isLoggedIn, slateId, sport, site]);





    useEffect(() => {
        if (persistentState.selectedPlayerIds && persistentState.selectedPlayerIds.length > 0) {
            updatePersistentState({
                selectedPlayerIds: persistentState.selectedPlayerIds
            });
        }
    }, [persistentState.selectedPlayerIds, updatePersistentState]);

    useEffect(() => {
        const newPositions = isShowdown
            ? ['CPT', 'FLEX']
            : sport === 'NFL'
                ? ['QB', 'RB', 'WR', 'TE', 'DST']
                : sport === 'NBA'
                    ? ['PG', 'SG', 'SF', 'PF', 'C']
                    : ['P', 'C', '1B', '2B', '3B', 'SS', 'OF'];

        if (JSON.stringify(newPositions) !== JSON.stringify(persistentState.positions)) {
            console.log('Positions updated');
            updatePersistentState({ positions: newPositions });
        }
    }, [isShowdown, sport, persistentState.positions]);





    useEffect(() => {
        if (sport === 'NFL') {
            updatePersistentState({ minProj: 3 });
        }
    }, [sport]);



    useEffect(() => {
        if (persistentState.players.length > 0 && !initialSelectionRef.current) {
            const idsToSelect = sport === 'NBA'
                ? persistentState.players.map(player => player.id)  // Select all NBA players initially
                : allPlayers.map(player => player.id);

            updatePersistentState({ selectedPlayerIds: idsToSelect });
            initialSelectionRef.current = true;
        }
    }, [persistentState.players, sport, updatePersistentState]);

    // Reset initialSelectionRef when sport changes
    useEffect(() => {
        initialSelectionRef.current = false;
    }, [sport]);

    useEffect(() => {
        if (persistentState.settings.positionLimits && persistentState.players.length > 0) {
            const updatedPlayers = persistentState.players.map(player => {
                const positionLimit =
                    persistentState.settings.positionLimits[player.Pos as keyof typeof persistentState.settings.positionLimits] ||
                    persistentState.settings.positionLimits.ALL;
                const hasEditedOlimit = persistentState.editedPlayers[player.id] && 'Olimit' in persistentState.editedPlayers[player.id];

                if (!hasEditedOlimit && player.Olimit !== positionLimit) {
                    return { ...player, Olimit: positionLimit };
                }
                return player;
            });
            if (JSON.stringify(updatedPlayers) !== JSON.stringify(persistentState.players)) {
                console.log('Players updated due to position limits change');
                updatePersistentState({ players: updatedPlayers });
            }
        }
    }, [persistentState.settings.positionLimits, persistentState.players, persistentState.editedPlayers]);



    useEffect(() => {
        if (persistentState.numberOfLineups > 0) {
            const newProgress = (persistentState.lineups.length / persistentState.totalLineups) * 100;
            if (Math.abs(newProgress - persistentState.lineupProgress) > 1) {
                console.log('Lineup progress updated');
                updatePersistentState({
                    lineupProgress: newProgress
                });
            }
        }
    }, [persistentState.lineups.length, persistentState.numberOfLineups, persistentState.totalLineups, persistentState.lineupProgress]);

    useEffect(() => {
        if (persistentState.lineups.length > 0) {
            const updatedLineups = persistentState.lineups.map(lineup => {
                let totalOwnership = 0;
                const updatedPlayers = lineup.players.map((player: { playerId: string; }) => {
                    const fullPlayerInfo = persistentState.players.find(p => String(p.playerId) === player.playerId || p.Player === player.playerId);
                    const ownership = fullPlayerInfo ? fullPlayerInfo.Own || 0 : 0;
                    totalOwnership += ownership;
                    return { ...player, ownership };
                });

                return {
                    ...lineup,
                    players: updatedPlayers,
                    totalOwnership
                };
            });

            if (JSON.stringify(updatedLineups) !== JSON.stringify(persistentState.lineups)) {
                updatePersistentState({ lineups: updatedLineups });
            }
        }
    }, [persistentState.players, persistentState.lineups, updatePersistentState]);

    useEffect(() => {
        if (persistentState.isOptimizerStarting) {
            const timeout = setTimeout(() => {
                if (persistentState.lineups.length === 0) {
                    updatePersistentState({ isOptimizerStarting: false });
                    alert("The optimizer timed out without producing any lineups. Please check your settings and try again.");
                }
            }, 60000);  // 1 minute timeout

            return () => clearTimeout(timeout);
        }
    }, [persistentState.isOptimizerStarting, persistentState.lineups.length, updatePersistentState]);

    // useEffect(() => {
    //     updatePersistentState({
    //         stackingInfo: {
    //             ...persistentState.stackingInfo,
    //             nflTeamStacks: persistentState.stackingInfo.nflTeamStacks,
    //         },
    //     });
    // }, [updatePersistentState, persistentState.stackingInfo]);


    useEffect(() => {
        const newExposures: { [key: string]: PlayerExposure } = {};

        persistentState.lineups.forEach(lineup => {
            lineup.players.forEach((player: { playerId: string; position: string }) => {
                const playerId = player.playerId;
                if (!newExposures[playerId]) {
                    newExposures[playerId] = {
                        id: playerId,
                        name: playerId,
                        position: player.position,
                        team: '',
                        count: 0
                    };
                }
                newExposures[playerId].count += 1;
            });
        });

        updatePersistentState({ playerExposures: newExposures });
    }, [persistentState.lineups, updatePersistentState]);

    const playerExposureList = useMemo(() => {
        return Object.values(playerExposures).map(data => ({
            id: data.id,
            playerId: data.id,
            name: data.name,
            position: data.position,
            team: data.team,
            exposure: data.count / lineups.length
        }));
    }, [playerExposures, lineups.length]);

    const convertToCSV = (rows: Player[], columns: GridColDef[]): string => {
        // Get the field names from the columns array
        const columnFields = columns.map((col) => col.field);

        // Create the CSV header based on the column headers
        const headers = columns.map((col) => col.headerName).join(',') + '\n';

        // Filter the rows to include only the data for the fields in the DataGrid columns
        const filteredRows = rows.map((row) => {
            return columnFields.map((field) => {
                if (field === 'ptsValue') {
                    const proj = row.Proj;
                    const salary = row.Salary;
                    if (proj != null && salary != null) {
                        return (proj / (salary / 1000)).toFixed(2);
                    }
                    return '';
                }
                if (field === 'Proj' && row.Pos === 'CPT') {
                    return (row.Proj / 1.5).toFixed(2);
                }
                if (field === 'Salary' && row.Pos === 'CPT') {
                    return (row.Salary / 1.5).toFixed(0);
                }
                const value = row[field as keyof Player];
                return typeof value === 'string' ? `"${value}"` : value;
            }).join(',');
        }).join('\n');

        // Return the headers and filtered rows as the CSV content
        return headers + filteredRows;
    };

    const convertToCSVlu = (lineups: BaseLineup[]): string => {
        const playerIdMap = new Map(persistentState.players.map(player => [player.Player, player.ID]));

        const isFanDuelShowdown = site === 'FanDuel' && isShowdown;
        const getPositionOrder = () => {
            if (isShowdown) {
                return site === 'FanDuel'
                    ? ['CPT', 'FLEX', 'FLEX', 'FLEX', 'FLEX']
                    : ['CPT', 'FLEX', 'FLEX', 'FLEX', 'FLEX', 'FLEX'];
            }

            if (sport === 'NBA') {
                return site === 'DraftKings'
                    ? ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL']
                    : ['PG', 'PG', 'SG', 'SG', 'SF', 'SF', 'PF', 'PF', 'C'];
            }

            if (sport === 'NFL') {
                return ['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'DST'];
            }

            return ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'];
        };
        const positionOrder = getPositionOrder();
        const headers = ['Lineup ID', 'Total Projection', 'Total Salary', 'Total Ownership', ...positionOrder];
        const csvRows = [headers.join(',')];

        const processShowdownLineup = (players: LineupPlayer[]): (LineupPlayer | null)[] => {
            const processed: (LineupPlayer | null)[] = new Array(isFanDuelShowdown ? 5 : 6).fill(null);
            const captain = players.find(p => p.position.toLowerCase() === (isFanDuelShowdown ? 'cpt' : 'cpt'));
            if (captain) processed[0] = captain;
            const flexPlayers = players.filter(p => p.position.toLowerCase() === 'flex');
            flexPlayers.forEach((player, index) => {
                if (index < (isFanDuelShowdown ? 4 : 5)) processed[index + 1] = player;
            });
            return processed;
        };

        const formatPlayerName = (playerId: string): string => {
            const player = persistentState.players.find(p => p.Player === playerId || p.id === playerId);
            if (player) {
                const name = player.Player;
                const id = playerIdMap.get(name);
                if (id) {
                    // Different formatting based on site
                    if (site === 'FanDuel') {
                        return `"${id}:${name}"`; // FanDuel format: ID:Player Name
                    } else {
                        return `"${name} (${id})"`; // DraftKings format: Player Name (ID)
                    }
                }
                return `"${name}"`;

            }
            return `"${playerId}"`;
        };

        const processNBALineup = (players: LineupPlayer[]): (LineupPlayer | null)[] => {
            if (site === 'DraftKings') {
                const processed = Array(8).fill(null);
                const positionCounts: { [key: string]: number } = {};
                let gAssigned = false;
                let fAssigned = false;
                let utilAssigned = false;

                // First pass: count primary positions
                players.forEach(player => {
                    const positions = player.position.split('/');
                    if (!positionCounts[positions[0]]) {
                        positionCounts[positions[0]] = 0;
                    }
                    positionCounts[positions[0]]++;
                });

                // Second pass: assign positions
                players.forEach(player => {
                    const positions = player.position.split('/');
                    let placed = false;

                    // Try primary position first
                    const primaryPos = positions[0];
                    const primaryIndex = ['PG', 'SG', 'SF', 'PF', 'C'].indexOf(primaryPos);
                    if (primaryIndex !== -1 && !processed[primaryIndex]) {
                        processed[primaryIndex] = player;
                        placed = true;
                    }

                    // Try G slot
                    if (!placed && !gAssigned && (positions.includes('PG') || positions.includes('SG'))) {
                        processed[5] = player;
                        gAssigned = true;
                        placed = true;
                    }

                    // Try F slot
                    if (!placed && !fAssigned && (positions.includes('SF') || positions.includes('PF'))) {
                        processed[6] = player;
                        fAssigned = true;
                        placed = true;
                    }

                    // Try UTIL slot
                    if (!placed && !utilAssigned) {
                        processed[7] = player;
                        utilAssigned = true;
                    }
                });

                return processed;
            } else {
                // FanDuel NBA
                const processed = Array(9).fill(null);
                const positionCounts: { [key: string]: number } = {
                    PG: 0, SG: 0, SF: 0, PF: 0, C: 0
                };

                players.forEach(player => {
                    const positions = player.position.split('/');
                    const primaryPos = positions[0];

                    if (positionCounts[primaryPos] < 2 || primaryPos === 'C') {
                        let slotIndex = -1;
                        if (primaryPos === 'PG') slotIndex = positionCounts[primaryPos];
                        else if (primaryPos === 'SG') slotIndex = 2 + positionCounts[primaryPos];
                        else if (primaryPos === 'SF') slotIndex = 4 + positionCounts[primaryPos];
                        else if (primaryPos === 'PF') slotIndex = 6 + positionCounts[primaryPos];
                        else if (primaryPos === 'C') slotIndex = 8;

                        if (slotIndex !== -1 && !processed[slotIndex]) {
                            processed[slotIndex] = player;
                            positionCounts[primaryPos]++;
                        }
                    }
                });

                return processed;
            }
        };


        const processNFLLineup = (players: LineupPlayer[]): (LineupPlayer | null)[] => {
            const processed: { [key: string]: LineupPlayer[] } = {
                QB: [], RB: [], WR: [], TE: [], DST: [], FLEX: []
            };

            players.forEach(player => {
                if (['RB', 'WR', 'TE'].includes(player.position)) {
                    if (processed[player.position].length < (player.position === 'TE' ? 1 : (player.position === 'RB' ? 2 : 3))) {
                        processed[player.position].push(player);
                    } else {
                        processed.FLEX.push(player);
                    }
                } else {
                    processed[player.position].push(player);
                }
            });

            return [
                processed.QB[0] || null,
                processed.RB[0] || null,
                processed.RB[1] || null,
                processed.WR[0] || null,
                processed.WR[1] || null,
                processed.WR[2] || null,
                processed.TE[0] || null,
                processed.FLEX[0] || null,
                processed.DST[0] || null
            ];
        };

        const processMLBLineup = (players: LineupPlayer[]): (LineupPlayer | null)[] => {
            const processedPlayers: (LineupPlayer | null)[] = new Array(10).fill(null);
            const positionCounts: { [key: string]: number } = {};
            const positionOrder = ['P', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF', 'OF', 'OF'];

            players.forEach(player => {
                const positionIndex = positionOrder.indexOf(player.position);
                if (positionIndex !== -1) {
                    const count = positionCounts[player.position] || 0;
                    let availableIndex = -1;

                    if (player.position === 'OF') {
                        availableIndex = positionOrder.findIndex((pos, index) => pos === 'OF' && !processedPlayers[index]);
                    } else {
                        availableIndex = positionOrder.indexOf(player.position, positionIndex + count);
                    }

                    if (availableIndex !== -1 && !processedPlayers[availableIndex]) {
                        processedPlayers[availableIndex] = player;
                        positionCounts[player.position] = (positionCounts[player.position] || 0) + 1;
                    }
                }
            });

            return processedPlayers;
        };


        lineups.forEach(lineup => {
            let processedPlayers: (LineupPlayer | null)[];
            if (isShowdown) {
                processedPlayers = processShowdownLineup(lineup.players);
            } else if (sport === 'NBA') {
                processedPlayers = processNBALineup(lineup.players);
            } else if (sport === 'NFL') {
                processedPlayers = processNFLLineup(lineup.players);
            } else {
                processedPlayers = processMLBLineup(lineup.players);
            }

            const row = [
                lineup.lineupId,
                lineup.totalProjection.toFixed(2),
                lineup.totalSalary,
                lineup.totalOwnership ? (lineup.totalOwnership).toFixed(2) + '%' : '',
                ...positionOrder.map((_, index) => {
                    const player = processedPlayers[index];
                    return player ? formatPlayerName(player.playerId) : '';
                })
            ];
            csvRows.push(row.join(','));
        });

        return csvRows.join('\n');
    };

    const downloadCSVlu = () => {
        const csv = convertToCSVlu(lineups);
        const BOM = '\uFEFF';
        const csvContent = BOM + csv;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${sport}_lineups.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    useEffect(() => {
        if (persistentState.selectedPlayerIds && persistentState.selectedPlayerIds.length > 0) {
            updatePersistentState({ selectedPlayerIds: persistentState.selectedPlayerIds });
        } else if (allPlayers.length > 0) {
            const allIds = allPlayers.map(player => player.id);
            updatePersistentState({ selectedPlayerIds: allIds });
        }
    }, [allPlayers, persistentState.selectedPlayerIds, updatePersistentState]);


    const downloadCSV = (csv: string, filename: string): void => {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) { // Feature detection
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleDownloadCSV = () => {
        if (userSubscription?.subscriptionTier === 'free') {
            // Optionally, you can show a message to the user here
            console.log('Free tier users cannot download players');
            return;
        }
        const csvData = convertToCSV(allPlayers, columns); // Assuming `filteredPlayers` and `columns` are the DataGrid rows and columns
        downloadCSV(csvData, 'player_pool.csv');
    };

    useEffect(() => {
        if (persistentState.positions.length > 0 && persistentState.selectedPositions.length === 0) {
            updatePersistentState({
                selectedPositions: [...persistentState.positions]
            });
        }
    }, [persistentState.positions, persistentState.selectedPositions, updatePersistentState]);


    const handlePositionToggle = useCallback((position: string) => {
        updatePersistentState(prevState => {
            if (position === 'ALL') {
                // If all players are showing (either through all positions selected or no positions selected),
                // then we want to clear all positions
                const shouldClearAll = prevState.selectedPositions.length === prevState.positions.length ||
                    prevState.selectedPositions.length === 0;
                return {
                    selectedPositions: shouldClearAll ? [] : [...prevState.positions]
                };
            }

            // Individual position toggle remains the same
            return {
                selectedPositions: prevState.selectedPositions.includes(position)
                    ? prevState.selectedPositions.filter(p => p !== position)
                    : [...prevState.selectedPositions, position]
            };
        });
    }, [updatePersistentState]);

    const handleTeamToggle = useCallback((team: string) => {
        const newTeams = team === 'ALL'
            ? (persistentState.selectedTeams.length === persistentState.games.length * 2
                ? []
                : persistentState.games.flatMap(game => [game.homeTeamAbbreviation, game.awayTeamAbbreviation]))
            : (persistentState.selectedTeams.includes(team)
                ? persistentState.selectedTeams.filter(t => t !== team)
                : [...persistentState.selectedTeams, team]);

        updatePersistentState({ selectedTeams: newTeams });
    }, [persistentState, updatePersistentState]);


    const handleCellEdit = (params: GridCellEditStopParams) => {
        const { id, field, value } = params;

        if (!['Proj', 'StdDev', 'Own', 'Olimit'].includes(field)) {
            return;
        }

        const numValue = Number(value);

        const updatedEditedPlayers = {
            ...persistentState.editedPlayers,
            [id]: {
                ...persistentState.editedPlayers[id],
                [field]: numValue,
            },
        };

        const updatedPlayers = persistentState.players.map(player =>
            player.id === id ? { ...player, [field]: numValue } : player
        );

        updatePersistentState({
            players: updatedPlayers,
            editedPlayers: updatedEditedPlayers,
        });
    };




    const columns: GridColDef[] = useMemo(() => [

        {
            field: 'Player',
            headerName: 'Name',
            width: 140,
            renderCell: (params) => (
                <Box display="flex" alignItems="center">

                    {params.value}
                </Box>
            ),
        },
        ...(sport === 'NFL' ? [{
            field: 'status',
            headerName: 'Status',
            width: 50,
            renderCell: (params: GridCellParams<Player>) => {
                const status = params.value as string;
                let displayText = '';
                let color = '';

                switch (status) {
                    case 'OUT':
                        displayText = 'O';
                        color = 'darkred';
                        break;
                    case 'QUESTIONABLE':
                        displayText = 'Q';
                        color = 'darkorange';
                        break;
                    case 'PROBABLE':
                        displayText = 'P';
                        color = 'darkgreen';
                        break;
                }

                return (
                    <Typography style={{ color, fontWeight: 'bold' }}>
                        {displayText}
                    </Typography>
                );
            },
        }] : []),
        {
            field: 'Pos',
            headerName: 'Pos',
            width: 70,
            renderCell: (params) => {
                const position = isShowdown ? params.row.playerPosition : (sport === 'NFL' ? params.row.playerPosition : params.row.Pos);
                return (
                    <Box display="flex" alignItems="center">
                        {isShowdown && position === 'CPT' && (
                            <FaCrown style={{ color: 'darkgoldenrod', marginRight: '5px' }} />
                        )}
                        {position}
                    </Box>
                );
            }
        },
        {
            field: 'Salary',
            headerName: 'Salary',
            width: 100,
            renderCell: (params: GridCellParams<Player>) => {
                return Math.round(params.row.Salary);
            },
        },
        {
            field: 'Team',
            headerName: 'Team',
            width: 80,
            renderCell: (params) => {
                return sport === 'NFL' ? params.row.teamAbbreviation : params.row.Team;
            }
        },
        {
            field: 'Opp',
            headerName: 'Opp',
            width: 80,
            renderCell: (params) => {
                if (sport === 'NFL') {
                    return params.row.opponent || '';
                } else {
                    return params.row.Opp || '';
                }
            }
        },
        // {
        //     field: sport === 'NBA' ? 'position' : 'Order',
        //     headerName: sport === 'MLB' ? 'Order' : sport === 'NFL' ? 'Depth' : 'Starting',
        //     width: 70,
        //     type: 'string',
        //     renderCell: (params: GridCellParams<Player>) => {
        //         if (sport === 'MLB') {
        //             return params.value === 0 ? 'P' : params.value;
        //         }

        //         if (sport === 'NFL') {
        //             const position = params.row.position || '';
        //             const parts = position.split('-');
        //             return parts.length >= 2 ? `${parts[parts.length - 2]}-${parts[parts.length - 1]}` : position;
        //         }

        //         // NBA Starting column
        //         const position = params.value as string;
        //         if (position?.toLowerCase().includes('start')) {
        //             return (
        //                 <Box display="flex" justifyContent="center" width="100%">
        //                     <CheckCircleIcon style={{ color: 'green' }} />
        //                 </Box>
        //             );
        //         }
        //         return null;
        //     }
        // },
        {
            field: 'Proj',
            headerName: 'Proj',
            width: 100,
            editable: true,
            renderHeader: () => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Proj
                    <EditIcon fontSize="small" sx={{ color: '#888', marginLeft: '4px' }} />
                </Box>
            ),
            renderCell: (params: GridCellParams<Player>) => {
                const projValue = persistentState.editedPlayers[params.id]?.Proj ?? params.row.Proj;

                return (
                    <Box sx={{
                        backgroundColor: '#f0f0f0',
                        padding: '4px',
                        borderRadius: '4px',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        {projValue !== 0 ? Number(projValue).toFixed(2) : ''}
                    </Box>
                );
            },
            sortComparator: (v1, v2, param1, param2) => {
                const getProjection = (value: any, row: any) => {
                    if (!row) {
                        return value ?? 0;
                    }
                    return persistentState.editedPlayers[row.id]?.Proj ?? row.Proj ?? 0;
                };
                const proj1 = getProjection(v1, param1.api.getRow(param1.id));
                const proj2 = getProjection(v2, param2.api.getRow(param2.id));
                return proj1 - proj2;
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const { props } = params;
                return { ...props, value: Number(props.value) || 0 };
            },
        },
        {
            field: 'Floor',
            headerName: 'Floor',
            width: 100,
            renderCell: (params: GridCellParams) => {
                const proj = Number(persistentState.editedPlayers[params.id]?.Proj ?? params.row.Proj);
                const stdDev = Number(persistentState.editedPlayers[params.id]?.StdDev ?? params.row.StdDev);
                if (isNaN(proj) || isNaN(stdDev)) return '';
                const floor = Math.max(0, proj - (0.8 * stdDev));
                return floor.toFixed(2);
            },
        },
        {
            field: 'Ceiling',
            headerName: 'Ceiling',
            width: 100,
            renderCell: (params: GridCellParams) => {
                const proj = Number(persistentState.editedPlayers[params.id]?.Proj ?? params.row.Proj);
                const stdDev = Number(persistentState.editedPlayers[params.id]?.StdDev ?? params.row.StdDev);
                if (isNaN(proj) || isNaN(stdDev)) return '';
                const ceiling = proj + stdDev;
                return ceiling.toFixed(2);
            },
        },
        {
            field: 'StdDev',
            headerName: 'StdDev',
            width: 100,
            editable: true,
            renderHeader: () => (
                <Tooltip title="This is the standard deviation of the player's projection based on the models range of outcomes, NOT based on historical actuals">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        StdDev
                        <EditIcon fontSize="small" sx={{ color: '#888', marginLeft: '4px' }} />
                    </Box>
                </Tooltip>

            ),
            renderCell: (params: GridCellParams) => {
                const value = editedPlayers[params.id]?.StdDev ?? params.value;
                return (
                    <Box
                        sx={{
                            backgroundColor: '#f0f0f0',
                            padding: '4px',
                            borderRadius: '4px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {value != null ? Number(value).toFixed(2) : ''}
                    </Box>
                );
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const { props } = params;
                return { ...props, value: Number(props.value) };
            },
        },
        {
            field: 'ptsValue',
            headerName: 'Value',
            width: 100,
            renderCell: (params: GridCellParams) => {
                const proj = Number(persistentState.editedPlayers[params.id]?.Proj ?? params.row.Proj);
                const salary = params.row.Salary;
                if (!proj || !salary) return '';
                const value = (proj / (salary / 1000)).toFixed(2);
                return value;
            },
        },
        // {
        //     field: 'Own',
        //     headerName: 'Own%',
        //     width: 100,
        //     editable: true,
        //     type: 'number',
        //     renderCell: (params: GridCellParams) => {
        //         return params.value != null ? `${Number(params.value).toFixed(2)}%` : '';
        //     },
        // },
        {
            field: 'Own',
            headerName: 'pOwn%',
            width: 100,
            editable: true,
            renderHeader: () => (
                <Tooltip title="This is the ML and Simulation based Projected Ownership for each player">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Own
                        <EditIcon fontSize="small" sx={{ color: '#888', marginLeft: '4px' }} />
                    </Box>
                </Tooltip>

            ),
            renderCell: (params: GridCellParams) => {
                const value = editedPlayers[params.id]?.StdDev ?? params.value;
                return (
                    <Box
                        sx={{
                            backgroundColor: '#f0f0f0',
                            padding: '4px',
                            borderRadius: '4px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {value != null ? `${Number(params.value).toFixed(2)}%` : ''}
                    </Box>
                );
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const { props } = params;
                return { ...props, value: Number(props.value) };
            },
        },
        {
            field: 'Olimit',
            headerName: 'Olimit',
            width: 100,
            editable: true,
            renderHeader: () => (
                <Tooltip title="This caps each player at their specific Own% Limit. We apply some buffer to prevent the optimizer from stalling out where needed">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Own Limit
                        <EditIcon fontSize="small" sx={{ color: '#888', marginLeft: '4px' }} />
                    </Box>
                </Tooltip>
            ),
            renderCell: (params: GridCellParams) => {
                const value = editedPlayers[params.id]?.Olimit ?? params.value;
                return (
                    <Box
                        sx={{
                            backgroundColor: '#f0f0f0',
                            padding: '4px',
                            borderRadius: '4px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {value != null ? `${Number(value).toFixed(0)}%` : ''}
                    </Box>
                );
            },
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const { props } = params;
                const value = Number(props.value);
                return { ...props, value: isNaN(value) ? 100 : Math.min(Math.max(value, 0), 100) };
            },
        },
        {
            field: 'gameStartTime',
            headerName: 'Game Time',
            width: 160,
            renderCell: (params: GridCellParams) => {
                const value = params.value as string | undefined;

                if (!value) {
                    return ''; // Return an empty string if value is undefined or null
                }

                const utcDate = new Date(value);
                const options: Intl.DateTimeFormatOptions = {
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZone: 'America/New_York', // Eastern Time Zone
                };
                const easternTime = utcDate.toLocaleTimeString('en-US', options);
                return easternTime;
            }
        },
    ], [persistentState.editedPlayers, sport, isShowdown]);



    const handleLineupNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        const maxLineups = getMaxLineups();
        if (isNaN(value) || value < 1) {
            updatePersistentState({
                numberOfLineups: 1,
                lineupError: "Minimum number of lineups is 1"
            });
        } else if (value > maxLineups) {
            updatePersistentState({
                numberOfLineups: maxLineups,
                lineupError: `Maximum number of lineups for your Subscription is ${maxLineups}`
            });
        } else {
            updatePersistentState({
                numberOfLineups: value,
                lineupError: null
            });
        }
    }, [updatePersistentState, getMaxLineups]);

    const handleMinProjChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value);
        updatePersistentState({ minProj: value });
    };

    const handleMinOwnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value);
        updatePersistentState({ minOwn: value });
    };

    const handleMaxOwnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value);
        updatePersistentState({ maxOwn: value });
    };

    const handleMinValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? '' : Number(e.target.value);
        updatePersistentState({ minValue: value });
    };





    const handleBuildLineups = async () => {
        const maxLineups = getMaxLineups();
        if (persistentState.numberOfLineups > maxLineups) {
            updatePersistentState({ lineupError: `Maximum number of lineups for your Subscription is ${maxLineups}` });
            return;
        }
        if (persistentState.isOptimizerStarting) return;

        updatePersistentState({
            isOptimizerStarting: true,
            lineups: [],
            warnings: [],
            lineupProgress: 0,
            totalLineups: persistentState.numberOfLineups,
        });

        const controller = new AbortController();
        setAbortController(controller);

        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                throw new Error('User not authenticated');
            }

            const idToken = await user.getIdToken();

            const playersWithEdits = persistentState.players.map(player => ({
                ...player,
                ...(persistentState.editedPlayers[player.id] || {}),
                isCaptain: player.id.endsWith('-CPT')
            }));

            const excludedPlayers = persistentState.players.filter(player => !persistentState.selectedPlayerIds.includes(player.id));

            const requestBody = {
                settings: {
                    ...persistentState.settings,
                    numberOfLineups: persistentState.numberOfLineups,
                    usePrimaryStacks: persistentState.stackingInfo.usePrimaryStacks,
                    useSecondaryStacks: persistentState.stackingInfo.useSecondaryStacks,
                    primaryStackCount: persistentState.stackingInfo.primaryStackCount,
                    secondaryStackCount: persistentState.stackingInfo.secondaryStackCount,
                    primaryStacks: persistentState.stackingInfo.primaryStacks,
                    secondaryStacks: persistentState.stackingInfo.secondaryStacks,
                    showdownCombinations: persistentState.settings.showdownCombinations,
                    globalMinQBStackSize: persistentState.settings.minQBStackSize,
                    globalMaxQBStackSize: persistentState.settings.maxQBStackSize,
                    site,
                },
                sport,
                date,
                slateId,
                minProj: persistentState.minProj === '' ? null : persistentState.minProj,
                minOwn: persistentState.minOwn === '' ? null : persistentState.minOwn,
                maxOwn: persistentState.maxOwn === '' ? null : persistentState.maxOwn,
                minValue: persistentState.minValue === '' ? null : persistentState.minValue,
                players: playersWithEdits,
                userId: user.uid,
                excludedPlayers: excludedPlayers,
                nbaStacks: sport === 'NBA' ? persistentState.stackingInfo.teamStacks
                    .filter(stack => stack.isPrimaryStack)
                    .map(stack => ({
                        team: stack.team,
                        minStackSize: stack.minStackSize || 2, // Default to 2 if not set
                        maxStackSize: stack.maxStackSize || 5,  // Default to 5 if not set
                    })) : [],
                nflStacks: sport === 'NFL' ? persistentState.stackingInfo.nflTeamStacks
                    .filter(stack => stack.isPrimaryStack)
                    .map(stack => ({
                        team: stack.team,
                        opponent: stack.opponent,
                        minQBstackSize: stack.minQBStackSize,
                        maxQBstackSize: stack.maxQBStackSize,
                        bringBackSize: stack.bringBackSize,
                        eligibleTeammates: Array.from(stack.eligibleTeammates),
                        eligibleOpponents: Array.from(stack.eligibleOpponents),
                    })) : [],
                showdownStacks: isShowdown ? showdownStackRules : undefined,
                anchorGroups: persistentState.playerGroups.map(group => ({
                    anchorPlayer: group.anchorPlayer,
                    minPlayers: group.minPlayers,
                    maxPlayers: group.maxPlayers,
                    includedPlayers: group.includedPlayers,
                    excludedPlayers: group.excludedPlayers
                })),
                playerPools: persistentState.playerPoolGroups.map(group => ({
                    players: group.players,
                    requiredCount: group.requiredCount
                })),
            };

            console.log('API Request Body:', JSON.stringify(requestBody, null, 2));

            const apiUrls = {
                'NFL': {
                    'Classic': 'https://ue-nfl-optov5-450591700064.us-central1.run.app/submit_job',
                    'Showdown': 'https://ue-shw-optov4-450591700064.us-central1.run.app/submit_job'
                },
                'MLB': {
                    'Classic': 'https://ue-mlb-optov2-450591700064.us-central1.run.app/submit_job',
                    'Showdown': 'https://ue-shw-optov4-450591700064.us-central1.run.app/submit_job'
                },
                'NBA': {
                    'Classic': 'https://ue-nba-optov2-450591700064.us-central1.run.app/submit_job',
                    'Showdown': 'https://ue-shw-optov4-450591700064.us-central1.run.app/submit_job'
                }
            } as const;

            const apiUrl = apiUrls[sport as keyof typeof apiUrls][slateType as 'Classic' | 'Showdown'];

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify(requestBody),
                signal: controller.signal
            });

            if (!response.ok) {
                throw new Error('Failed to submit job');
            }

            const reader = response.body!.getReader();
            const decoder = new TextDecoder();
            const newLineups: BaseLineup[] = [];

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;
                const chunk = decoder.decode(value);
                const lines = chunk.split('\n\n');
                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        const data = JSON.parse(line.slice(6));
                        if (data.type === 'intermediate') {
                            newLineups.push(data.data);
                            updatePersistentState((prevState: PersistentState) => ({
                                lineups: [...prevState.lineups, data.data],
                                lineupProgress: prevState.lineupProgress + 1
                            }));
                        } else if (data.type === 'final') {
                            // Ensure we update with all lineups, including the last one
                            updatePersistentState((prevState: PersistentState) => ({
                                lineups: newLineups,
                                lineupProgress: newLineups.length,
                                isOptimizerStarting: false
                            }));
                        } else if (data.type === 'error') {
                            console.error('Error:', data.message);
                            updatePersistentState({ isOptimizerStarting: false });
                            alert('Error building lineups: ' + data.message);
                        }
                    }
                }
            }
            updatePersistentState({ lineups: newLineups });

        } catch (error: unknown) {
            if (error instanceof Error) {
                if (error.name === 'AbortError') {
                    console.log('Lineup building cancelled');
                } else {
                    console.error('Error:', error.message);
                    alert(`Unable to build lineups: ${error.message}`);
                }
            } else {
                console.error('An unknown error occurred:', error);
                alert('An unexpected error occurred. Please try again.');
            }
        } finally {
            updatePersistentState({ isOptimizerStarting: false });
            setAbortController(null);
        }
    };

    const handleCancelBuild = useCallback(() => {
        if (abortController) {
            abortController.abort();
            updatePersistentState({ isOptimizerStarting: false });
            setAbortController(null);
        }
    }, [abortController, updatePersistentState]);

    const handleProcessRowUpdateError = (error: Error) => {
        console.error('Error processing row update:', error);
    };

    const memoizedGameDisplays = useMemo(() => {
        return persistentState.games.map(game => (
            <GameDisplay
                key={game.id}
                game={game}
                selectedTeams={persistentState.selectedTeams}
                onTeamToggle={handleTeamToggle}
            />
        ));
    }, [persistentState.games, persistentState.selectedTeams, handleTeamToggle]);

    // const processNFLPlayersIntoTeamStacks = (players: Player[]): NFLTeamStack[] => {
    //     const teamMap = new Map<string, Player[]>();
    //     const opponentMap = new Map<string, string>();

    //     players.forEach(player => {
    //         const team = player.teamAbbreviation;
    //         const opponent = player.opponent ? player.opponent.replace(/^(vs |@ )/, '') : '';
    //         if (!team || !opponent) return;

    //         if (!teamMap.has(team)) {
    //             teamMap.set(team, []);
    //             opponentMap.set(team, opponent);
    //         }
    //         teamMap.get(team)?.push(player);
    //     });

    //     return Array.from(teamMap).map(([team, teamPlayers]): NFLTeamStack => {
    //         const opponent = opponentMap.get(team) || '';
    //         return {
    //             team,
    //             opponent,
    //             players: teamPlayers,
    //             isPrimaryStack: stackingInfo.primaryStacks.includes(team),
    //             isSecondaryStack: stackingInfo.secondaryStacks.includes(team),
    //             allPlayersIncluded: true,
    //             eligibleTeammates: new Set(teamPlayers.map(p => p.Player)),
    //             eligibleOpponents: new Set(teamMap.get(opponent)?.map(p => p.Player) || []),
    //             allTeammatesSelected: false,
    //             allOpponentsSelected: false,
    //             qbStackSize: 2,
    //             bringBackSize: 0,
    //         };
    //     });
    // };


    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow } as Player;

        if (updatedRow.Proj && updatedRow.Salary) {
            updatedRow.ptsValue = Number((updatedRow.Proj / (updatedRow.Salary / 1000)).toFixed(2));
        }

        updatePersistentState({
            players: persistentState.players.map(player =>
                player.id === updatedRow.id ? { ...player, ...updatedRow } : player
            ),
            editedPlayers: {
                ...persistentState.editedPlayers,
                [updatedRow.id]: {
                    ...persistentState.editedPlayers[updatedRow.id],
                    Proj: updatedRow.Proj,
                    StdDev: updatedRow.StdDev,
                    Own: updatedRow.Own,
                    Olimit: updatedRow.Olimit,
                }
            }
        });

        return updatedRow;
    };

    // const applyGlobalSettings = (positionSettings: { [key: string]: number }) => {
    //     setPlayers(prevPlayers =>
    //         prevPlayers.map(player => ({
    //             ...player,
    //             Olimit: positionSettings[player.Pos] || player.Olimit
    //         }))
    //     );
    // };

    const UploadButton = () => {
        const { userSubscription } = useSubscription();
        const fileInputRef = useRef<HTMLInputElement>(null);

        const handleUploadClick = () => {
            console.log('Upload button clicked');
            fileInputRef.current?.click();
        };

        if (userSubscription?.subscriptionTier === 'free') {
            return (
                <Tooltip title="Upgrade your subscription to use this feature">
                    <Chip
                        icon={<LockIcon />}
                        label="Upload Projections"
                        size="medium"
                        sx={{ mr: 1 }}
                    />
                </Tooltip>
            );
        }

        return (
            <>
                <Chip
                    icon={persistentState.isLoading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
                    label={persistentState.isLoading ? "Uploading..." : "Upload Projections"}
                    onClick={handleUploadClick}
                    color="primary"
                    size="medium"
                    sx={{ mr: 1 }}
                    disabled={persistentState.isLoading}
                />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    accept=".csv"
                />
            </>
        );
    };

    const [regularPlayers, cptPlayers] = React.useMemo(() => {
        let filtered = persistentState.players.filter(player =>
            (sport === 'NFL' ? persistentState.selectedPositions.includes(player.playerPosition || '') : persistentState.selectedPositions.includes(player.Pos)) &&
            persistentState.selectedTeams.includes(sport === 'NFL' ? player.teamAbbreviation || '' : player.Team || '') &&
            (player.Player.toLowerCase().includes((persistentState.searchTerm || '').toLowerCase()) ||
                (sport === 'NFL' ? player.teamAbbreviation : player.Team)?.toLowerCase().includes((persistentState.searchTerm || '').toLowerCase())) &&
            (persistentState.minProj === '' || player.Proj >= persistentState.minProj) &&
            (persistentState.minOwn === '' || player.Own >= persistentState.minOwn) &&
            (persistentState.maxOwn === '' || player.Own <= persistentState.maxOwn) &&
            (persistentState.minValue === '' || (player.Proj / player.Salary) * 1000 >= persistentState.minValue)
        );

        if (isShowdown) {
            const captainPlayers = allPlayers.map(player => ({
                ...player,
                id: `${player.id}-CPT`,
                Player: `${player.Player} - CPT`,
                Pos: 'CPT',
                originalSalary: player.Salary,
                originalProj: player.Proj,
                Salary: player.Salary * 1.5,
                Proj: player.Proj * 1.5,
            }));
            return [filtered, captainPlayers];
        }
        return [filtered, []];
    }, [persistentState.players, persistentState.selectedPositions, persistentState.selectedTeams, persistentState.searchTerm,
    persistentState.minProj, persistentState.minOwn, persistentState.maxOwn, persistentState.minValue, sport, isShowdown]);


    useEffect(() => {
        if (regularPlayers.length > 0 && !initialSelectionRef.current) {
            const regularIds = regularPlayers.map(player => player.id);
            let cptIds: string[] = [];
            if (isShowdown) {
                cptIds = cptPlayers.map(player => player.id);
            }
            updatePersistentState({
                selectedPlayerIds: [...regularIds, ...cptIds],
                selectedRegularRows: regularIds,
                selectedCPTRows: cptIds
            });
            initialSelectionRef.current = true;
        }
    }, [regularPlayers, cptPlayers, isShowdown, updatePersistentState]);

    const DownloadPlayersButton = () => {
        const { userSubscription } = useSubscription();

        if (userSubscription?.subscriptionTier === 'free') {
            return (
                <Tooltip title="Upgrade your subscription to use this feature">
                    <Chip
                        icon={<LockIcon />}
                        label="Download Players"
                        size="medium"
                        sx={{ mr: 1 }}
                    />
                </Tooltip>
            );
        }

        return (
            <Chip
                icon={<DownloadIcon />}
                label="Download Players"
                onClick={handleDownloadCSV}
                color="primary"
                size="medium"
            />
        );
    };


    if (!isLoggedIn) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 64px)', // Adjust this if your app bar height is different
                p: 3,
                textAlign: 'center'
            }}>
                <Typography variant="h4" gutterBottom>
                    Welcome to the DFS OS
                </Typography>
                <Typography variant="h5" color="error" sx={{ mb: 2 }}>
                    You need to be logged in to use this feature
                </Typography>
                <Typography variant="body1">
                    Please use the login button in the top right corner to access all features.
                </Typography>
                <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                    If you don't have an account yet, you can sign up for free!
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{
            width: '100%',
            maxWidth: isShowdown ? '100%' : { xs: '100%', sm: '90%', md: '90%', lg: '90%' },
            marginLeft: 0,
            marginRight: isShowdown ? 0 : 'auto',
            overflowX: 'hidden',
            pt: 1
        }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card elevation={3}>
                        <CardContent sx={{ p: 1 }}>
                            <Grid container spacing={1} alignItems="flex-start">
                                <Grid item xs={12} md={3}>
                                    <Typography variant="subtitle2" gutterBottom>Positions</Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, ml: 1 }}>
                                        <Chip
                                            key="ALL"
                                            label="ALL"
                                            onClick={() => handlePositionToggle('ALL')}
                                            color={persistentState.selectedPositions.length === persistentState.positions.length ? 'primary' : 'default'}
                                            size="small"
                                        />
                                        {persistentState.positions.map(position => (
                                            <Chip
                                                key={position}
                                                label={position}
                                                onClick={() => handlePositionToggle(position)}
                                                color={persistentState.selectedPositions.includes(position) ? 'primary' : 'default'}
                                                size="small"
                                            />
                                        ))}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={7}>
                                    <Typography variant="subtitle2" gutterBottom>Games</Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', gap: 0.5, pb: .5 }}>
                                        {persistentState.games.length > 1 && (
                                            <Chip
                                                key="ALL"
                                                label="ALL"
                                                onClick={() => handleTeamToggle('ALL')}
                                                color={persistentState.selectedTeams.length === persistentState.games.length * 2 ? 'primary' : 'default'}
                                                size="small"
                                            />
                                        )}
                                        {persistentState.games.map(game => (
                                            <GameDisplay
                                                key={game.id}
                                                game={game}
                                                selectedTeams={persistentState.selectedTeams}
                                                onTeamToggle={handleTeamToggle}
                                            />
                                        ))}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Typography variant="subtitle2" gutterBottom>Filters</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <TextField label="Min Proj" size="small" value={persistentState.minProj} onChange={handleMinProjChange} fullWidth />
                                            <TextField label="Min Own" size="small" value={persistentState.minOwn} onChange={handleMinOwnChange} fullWidth sx={{ mt: 1 }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField label="Max Own" size="small" value={persistentState.maxOwn} onChange={handleMaxOwnChange} fullWidth />
                                            <TextField label="Min Value" size="small" value={persistentState.minValue} onChange={handleMinValueChange} fullWidth sx={{ mt: 1 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card elevation={3}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, flexWrap: 'wrap', gap: 1 }}>
                                <TextField
                                    label="Number of Lineups"
                                    type="number"
                                    value={persistentState.numberOfLineups}
                                    onChange={handleLineupNumberChange}
                                    size="small"
                                    sx={{ width: 150 }}
                                    error={!!persistentState.lineupError}
                                    helperText={persistentState.lineupError}
                                    disabled={persistentState.isOptimizerStarting}
                                />
                                {isOptimizerStarting ? (
                                    <Button
                                        onClick={handleCancelBuild}
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                    >
                                        Cancel Build
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={handleBuildLineups}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={isOptimizerStarting}
                                    >
                                        BUILD LINEUPS
                                    </Button>
                                )}
                                {persistentState.lineups.length > 0 && (
                                    <Box sx={{ width: '200px' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Lineups ({persistentState.lineups.length}/{persistentState.numberOfLineups})
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={(persistentState.lineups.length / persistentState.numberOfLineups) * 100}
                                            sx={{ height: 8, borderRadius: 5, mb: 1 }}
                                            color="secondary"
                                        />
                                        <Chip
                                            icon={<DownloadIcon />}
                                            label="Download Lineups"
                                            onClick={downloadCSVlu}
                                            color="secondary"
                                            size="medium"
                                        />
                                    </Box>
                                )}
                                <TextField
                                    label="Search Players"
                                    variant="outlined"
                                    size="small"
                                    value={persistentState.searchTerm}
                                    onChange={(e) => updatePersistentState({ searchTerm: e.target.value })}
                                    sx={{ width: { xs: '100%', sm: '25%' } }}
                                />
                                <Box>
                                    {usingCustomProjections && (
                                        <Tooltip title="Using Custom Projections">

                                            <Chip
                                                icon={<FlareTwoToneIcon />}
                                                color="secondary"
                                                size="small"
                                                sx={{ mx: 1 }}
                                            />
                                        </Tooltip>
                                    )}
                                    <UploadButton />
                                    <DownloadPlayersButton />
                                </Box>
                            </Box>

                            <Paper style={{ height: 500, width: '100%', overflowX: 'auto' }}>
                                {persistentState.players.length > 0 ? (
                                    <DataGrid
                                        key={persistentState.players.length}
                                        rows={allPlayers}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { pageSize: 50, page: 0 },
                                            },
                                        }}
                                        rowHeight={30}
                                        pageSizeOptions={[50, 75, 100]}
                                        checkboxSelection
                                        disableRowSelectionOnClick
                                        onCellEditStop={handleCellEdit}
                                        processRowUpdate={processRowUpdate}
                                        onProcessRowUpdateError={handleProcessRowUpdateError}
                                        sx={{
                                            '& .editable-cell': editableCellStyle,
                                            '& .MuiDataGrid-columnHeaders': {
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                                height: 55,
                                                backgroundColor: '#f5f5f5',
                                                fontWeight: 'bold',
                                            },
                                            '& .MuiDataGrid-row:nth-of-type(odd)': {
                                                backgroundColor: '#f9f9f9',
                                            },
                                            '& .MuiDataGrid-row:nth-of-type(even)': {
                                                backgroundColor: '#ffffff',
                                            },
                                            '& .MuiDataGrid-row:hover': {
                                                backgroundColor: '#e0e0e0',
                                            },
                                            '& .MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                            },
                                        }}
                                        isRowSelectable={() => true}
                                        rowSelectionModel={persistentState.selectedPlayerIds}
                                        onRowSelectionModelChange={(newSelectionModel) => {
                                            updatePersistentState({ selectedPlayerIds: newSelectionModel as string[] });
                                        }}
                                        getRowId={(row) => row.id}
                                    />
                                ) : (
                                    <Typography sx={{ p: 2 }}>No players available for the selected slate.</Typography>
                                )}
                            </Paper>
                        </CardContent>
                    </Card>
                </Grid>

                {isOptimizerStarting && lineups.length === 0 && (
                    <Grid item xs={12}>
                        <Card elevation={3}>
                            <CardContent>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <CircularProgress size={36} sx={{ mr: 2 }} />
                                    <Typography>Firing up the optimizer...</Typography>

                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {warnings.length > 0 && (
                    <Grid item xs={12}>
                        <Alert severity="warning" sx={{ mt: 2 }}>
                            {warnings.length} lineup(s) could not be generated due to restrictive settings.
                            <ul>
                                {warnings.map((warning, index) => (
                                    <li key={index}>{warning}</li>
                                ))}
                            </ul>
                        </Alert>
                    </Grid>
                )}

                <Grid item xs={12} md={isSmallScreen ? 12 : 8}>
                    <Card elevation={3}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Lineups</Typography>
                            <LineupTable lineups={persistentState.lineups} sport={sport as 'MLB' | 'NFL'} isShowdown={isShowdown} site={site as 'DraftKings' | 'FanDuel'} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={isSmallScreen ? 12 : 4}>
                    <Card elevation={3}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Player Exposures</Typography>
                            <PlayerExposureTable
                                playerExposures={playerExposureList}
                                sport={sport as 'MLB' | 'NFL'}
                                isShowdown={isShowdown}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );

});

export default PlayerPool;