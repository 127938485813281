import React, { useState, useMemo } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Typography, Paper, Tooltip, IconButton, Switch, FormControlLabel } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faPercent, faSackDollar, faChartLine, faUsers, faCoins } from '@fortawesome/free-solid-svg-icons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface LineupResultsTableProps {
    data: any[];
    sport: 'MLB' | 'NFL' | 'NBA';
    isShowdown: boolean;
    site: 'DraftKings' | 'FanDuel';
}

const LineupResultsTable: React.FC<LineupResultsTableProps> = ({ data, sport, isShowdown, site }) => {
    console.log('LineupResultsTable render:', { dataLength: data.length, sport, isShowdown });
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const getBarColor = (percentage: number) => {
        if (percentage >= 80) return 'linear-gradient(90deg, #4CAF50, #8BC34A)';
        if (percentage <= 10) return 'linear-gradient(90deg, #F44336, #FF9800)';
        return 'linear-gradient(90deg, #FF9800, #FFC107)';
    };

    const renderBarChart = (value: number, maxValue: number) => {
        const percentage = Math.min((value / maxValue) * 100, 100);
        const barColor = getBarColor(percentage);

        return (
            <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: `${percentage}%`,
                        height: '100%',
                        background: barColor,
                        opacity: 0.7,
                        transition: 'width 0.3s ease-in-out',
                    }}
                />
                <Typography
                    variant="body2"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'text.primary',
                        fontWeight: 'bold',
                        textShadow: '0px 0px 2px white',
                    }}
                >
                    {`${value.toFixed(2)}%`}
                </Typography>
            </Box>
        );
    };

    const getPositionColumns = (): string[] => {
        if (isShowdown) {
            return site === 'FanDuel'
                ? ['CPT1', 'FLEX1', 'FLEX2', 'FLEX3', 'FLEX4']
                : ['CPT1', 'FLEX1', 'FLEX2', 'FLEX3', 'FLEX4', 'FLEX5'];
        }

        switch (sport) {
            case 'NFL':
                return ['QB1', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE1', 'FLEX', 'DST1'];
            case 'MLB':
                return ['P1', 'P2', 'C1', '1B1', '2B1', '3B1', 'SS1', 'OF1', 'OF2', 'OF3'];
            case 'NBA':
                if (site === 'DraftKings') {
                    return ['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL'];
                }
                return ['PG1', 'PG2', 'SG1', 'SG2', 'SF1', 'SF2', 'PF1', 'PF2', 'C'];
            default:
                return [];
        }
    };

    const columns: GridColDef[] = useMemo(() => {
        const baseColumns: GridColDef[] = [

            { field: 'lineup_id', headerName: 'ID', width: 70 },
            {
                field: 'win_pct',
                headerName: '',
                width: 80,
                renderHeader: () => (
                    <Tooltip title="Win %">
                        <Box display="flex" justifyContent="center" width="100%">
                            <FontAwesomeIcon icon={faTrophy} style={{ fontSize: '1.5rem', color: '#dc5f00' }} />
                        </Box>
                    </Tooltip>
                ),
                renderCell: (params) => renderBarChart(params.value, .75),
            },
            {
                field: 'top1p',
                headerName: '',
                width: 80,
                renderHeader: () => (
                    <Tooltip title="Top 1%">
                        <Box display="flex" justifyContent="center" width="100%">
                            <Typography variant="h6" style={{ color: '#dc5f00', fontWeight: 'bold' }}>1%</Typography>
                        </Box>
                    </Tooltip>
                ),
                renderCell: (params) => renderBarChart(params.value, 2),
            },
            {
                field: 'top10p',
                headerName: '',
                width: 80,
                renderHeader: () => (
                    <Tooltip title="Top 10%">
                        <Box display="flex" justifyContent="center" width="100%">
                            <Typography variant="h6" style={{ color: '#dc5f00', fontWeight: 'bold' }}>10%</Typography>
                        </Box>
                    </Tooltip>
                ),
                renderCell: (params) => renderBarChart(params.value, 3),
            },
            {
                field: 'cash_pct',
                headerName: '',
                width: 80,
                renderHeader: () => (
                    <Tooltip title="Cash %">
                        <Box display="flex" justifyContent="center" width="100%">
                            <FontAwesomeIcon icon={faSackDollar} style={{ fontSize: '1.5rem', color: '#dc5f00' }} />
                        </Box>
                    </Tooltip>
                ),
                renderCell: (params) => renderBarChart(params.value, 30),
            },
            {
                field: 'roi',
                headerName: '',
                width: 80,
                renderHeader: () => (
                    <Tooltip title="ROI">
                        <Box display="flex" justifyContent="center" width="100%">
                            <FontAwesomeIcon icon={faChartLine} style={{ fontSize: '1.5rem', color: '#dc5f00' }} />
                        </Box>
                    </Tooltip>
                ),
                renderCell: (params) => {
                    const value = params.value as number;
                    let color = 'black';
                    if (value < 0) color = 'red';
                    else if (value >= 0 && value < 10) color = 'orange';
                    else if (value > 15) color = 'green';
                    return <span style={{ color, fontWeight: Math.abs(value) > 50 ? 'bold' : 'normal' }}>{`${value.toFixed(2)}%`}</span>;
                },
            },
            {
                field: 'UEscore',
                headerName: '',
                width: 80,
                renderHeader: () => (
                    <Tooltip title="UE Score">
                        <Box display="flex" justifyContent="center" width="100%">
                            <Typography variant="h6" style={{ color: '#dc5f00', fontWeight: 'bold' }}>UE</Typography>
                        </Box>
                    </Tooltip>
                ),
                renderCell: (params) => {
                    const value = params.value as number;
                    let color = 'black';
                    if (value < 10) color = 'red';
                    else if (value >= 10 && value < 25) color = 'orange';
                    else if (value > 25) color = 'green';
                    return <span style={{ color, fontWeight: Math.abs(value) > 25 ? 'bold' : 'normal' }}>{`${value.toFixed(2)}`}</span>;
                },
            },
            {
                field: 'win_leverage',
                headerName: '',
                width: 80,
                renderHeader: () => (
                    <Tooltip title="Win Leverage">
                        <Box display="flex" justifyContent="center" width="100%">
                            <FontAwesomeIcon icon={faUsers} style={{ fontSize: '1.5rem', color: '#dc5f00' }} />
                        </Box>
                    </Tooltip>
                ),
                renderCell: (params) => params.value != null ? `${(params.value).toFixed(2)}%` : '',
            },
        ];

        if (!isShowdown) {
            baseColumns.splice(1, 0,
                { field: 'main_stack', headerName: 'Stack', width: 80 },
                { field: 'stack_size', headerName: 'Stack Size', width: 70 }
            );
        }

        if (isExpanded) {
            const positionColumns: GridColDef[] = getPositionColumns().map(pos => ({
                field: pos,
                headerName: pos,
                width: 120,
                renderCell: (params: GridRenderCellParams) => params.value || '',
            }));
            return [...baseColumns, ...positionColumns];
        }

        return baseColumns;
    }, [sport, isShowdown, isExpanded]);

    const rows = useMemo(() => {
        return data.map(row => ({
            id: row.lineup_id,
            ...row
        }));
    }, [data]);


    return (
        <Paper elevation={3} sx={{ width: '90%', maxWidth: '1200px', margin: 'auto' }}>
            <Box sx={{ p: 2 }}>
                <FormControlLabel
                    control={<Switch checked={isExpanded} onChange={toggleExpansion} />}
                    label="Show Players"
                />
            </Box>
            <Box sx={{ height: 400, width: '100%', overflow: 'hidden' }}>
                <div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
                    <div style={{ height: '100%', width: isExpanded ? 'max-content' : '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 50, page: 0 },
                                },
                            }}
                            pageSizeOptions={[50, 75, 10]}
                            density="compact"
                            disableColumnMenu
                        />
                    </div>
                </div>
            </Box>
        </Paper>
    );
};

export default LineupResultsTable;