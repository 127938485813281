import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { auth, db } from '../firebase';
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    User as FirebaseUser,
    UserCredential
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';

interface User extends FirebaseUser {
    subscriptionTier: 'free' | 'rookie' | 'mvp';
    subscriptionEnd: Date | null;
}

interface AuthContextType {
    isLoggedIn: boolean;
    isLoading: boolean;
    login: (email: string, password: string) => Promise<void>;
    signup: (email: string, password: string) => Promise<UserCredential>;
    logout: () => Promise<void>;
    user: User | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);

    const fetchUserData = useCallback(async (firebaseUser: FirebaseUser) => {
        try {
            const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                return {
                    ...firebaseUser,
                    subscriptionTier: userData.subscriptionTier || 'free',
                    subscriptionEnd: userData.subscriptionEnd ? userData.subscriptionEnd.toDate() : null,
                } as User;
            } else {
                await setDoc(doc(db, 'users', firebaseUser.uid), {
                    email: firebaseUser.email,
                    subscriptionTier: 'free',
                    subscriptionEnd: null,
                });
                return {
                    ...firebaseUser,
                    subscriptionTier: 'free',
                    subscriptionEnd: null,
                } as User;
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
        }
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            setIsLoading(true);
            if (firebaseUser) {
                const userData = await fetchUserData(firebaseUser);
                setUser(userData);
                setIsLoggedIn(!!userData);
            } else {
                setUser(null);
                setIsLoggedIn(false);
            }
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, [fetchUserData]);

    const login = async (email: string, password: string) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const signup = async (email: string, password: string): Promise<UserCredential> => {
        try {
            return await createUserWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error('Signup error:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error('Logout error:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, isLoading, login, signup, logout, user }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};