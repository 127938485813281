import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Slider, Checkbox, FormControlLabel, Paper, Grid, Radio, RadioGroup, Switch, Tooltip } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { useSettings } from '../../context/SettingsContext';
import { useSlateContext } from '../../context/SlateContext';
import { useSubscription } from '../../context/SubscriptionContext';
import { LockOutlined } from '@mui/icons-material';
import { usePersistentState } from '../../context/PersistentStateContext';

interface PositionLimits {
    ALL: number;
    P: number;
    C: number;
    '1B': number;
    '2B': number;
    '3B': number;
    SS: number;
    OF: number;
    [key: string]: number;
}

function Settings() {
    const { persistentState, updatePersistentState } = usePersistentState();
    const { site, sport } = useSlateContext();
    const { userSubscription } = useSubscription();

    const isFreeTier = userSubscription?.subscriptionTier === 'free';

    // useEffect(() => {
    //     updatePersistentState({ positionLimits: persistentState.settings.positionLimits });
    // }, [persistentState.settings.positionLimits, updatePersistentState]);

    const handleInputChange = (field: string, value: any) => {
        updatePersistentState({
            settings: {
                ...persistentState.settings,
                [field]: value
            }
        });
    };

    const getMaxSalary = () => {
        return site === 'FanDuel' ? 60000 : 50000;
    };

    const handlePositionLimitChange = (position: string, value: string) => {
        const numValue = Number(value);
        if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
            let newPositionLimits = { ...persistentState.settings.positionLimits };

            if (position === 'ALL') {
                Object.keys(newPositionLimits).forEach(pos => {
                    newPositionLimits[pos] = numValue;
                });
            } else {
                newPositionLimits[position] = numValue;
            }

            updatePersistentState({ settings: { ...persistentState.settings, positionLimits: newPositionLimits } });
        }
    };

    const handleFlexPositionChange = (position: 'WR' | 'RB' | 'TE', checked: boolean) => {
        updatePersistentState({
            settings: {
                ...persistentState.settings,
                flexPositions: {
                    ...persistentState.settings.flexPositions,
                    [position]: checked
                }
            }
        });
    };

    const renderPositionLimits = () => {
        const positions = sport === 'NBA'
            ? ['ALL'] // For NBA, only show the ALL option
            : sport === 'NFL'
                ? ['ALL', 'QB', 'RB', 'WR', 'TE', 'DST']
                : ['ALL', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF'];

        return (
            <Grid container spacing={1}>
                {positions.map((position) => (
                    <Grid item xs={6} sm={4} md={2} key={position}>
                        <TextField
                            label={position}
                            type="number"
                            value={persistentState.settings.positionLimits[position]}
                            onChange={(e) => handlePositionLimitChange(position, e.target.value)}
                            inputProps={{ min: 0, max: 100 }}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };


    // const renderPositionLimits = () => {
    //     const positions = sport === 'NFL' ? ['ALL', 'QB', 'RB', 'WR', 'TE', 'DST'] : ['ALL', 'P', 'C', '1B', '2B', '3B', 'SS', 'OF'];
    //     return (
    //         <Grid container spacing={1}>
    //             {positions.map((position) => (
    //                 <Grid item xs={6} sm={4} md={2} key={position}>
    //                     <TextField
    //                         label={position}
    //                         type="number"
    //                         value={positionLimits[position as keyof typeof positionLimits]}
    //                         onChange={(e) => handlePositionLimitChange(position, e.target.value)}
    //                         inputProps={{ min: 0, max: 100 }}
    //                         fullWidth
    //                         size="small"
    //                     />
    //                 </Grid>
    //             ))}
    //         </Grid>
    //     );
    // };

    // useEffect(() => {
    //     if (settings.positionLimits) {
    //         setPositionLimits(settings.positionLimits);
    //     }
    // }, [settings.positionLimits]);

    const handleDistributionMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const method = event.target.value;
        updatePersistentState({
            settings: {
                ...persistentState.settings,
                useRandomness: method === 'useRandomness',
                usePlayerDistributions: method === 'usePlayerDistributions',
                useCorrelatedDistributions: method === 'useCorrelatedDistributions',
            }
        });
    };

    const getSelectedDistributionMethod = () => {
        if (persistentState.settings.useRandomness) return 'useRandomness';
        if (persistentState.settings.usePlayerDistributions) return 'usePlayerDistributions';
        if (persistentState.settings.useCorrelatedDistributions) return 'useCorrelatedDistributions';
        return 'useRandomness'; // Default value
    };

    // useEffect(() => {
    //     setPositionLimits(settings.positionLimits);
    // }, [settings.positionLimits]);

    useEffect(() => {
        const maxSalary = getMaxSalary();
        const currentMin = persistentState.settings.salaryRange[0];
        const currentMax = persistentState.settings.salaryRange[1];

        // Only update if the site has changed or if the salary range is not set
        if (currentMin === 0 || currentMax === 0 || currentMax > maxSalary) {
            updatePersistentState({
                settings: {
                    ...persistentState.settings,
                    salaryRange: [maxSalary * 0.5, maxSalary] // Set default range from 50% to 100% of max salary
                }
            });
        }
    }, [site, updatePersistentState, persistentState.settings.salaryRange]);

    return (

        <Box sx={{ maxWidth: '100%', overflowX: 'auto', flexDirection: 'column', p: 1 }}>
            <Card elevation={3}>
                <CardContent sx={{ display: 'flex', gap: 6 }}>
                    <Box sx={{ flex: 1, ml: 4 }}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem' }}>Basic Settings</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={10}>
                                <Typography variant="body2">Salary Range</Typography>
                                <Slider
                                    value={persistentState.settings.salaryRange}
                                    onChange={(_, newValue) => handleInputChange('salaryRange', newValue)}
                                    min={25000}
                                    max={getMaxSalary()}
                                    step={100}
                                    valueLabelDisplay="auto"
                                    marks={[
                                        { value: 25000, label: '$25k' },
                                        { value: 35000, label: '$35k' },
                                        { value: 45000, label: '$45k' },
                                        { value: getMaxSalary(), label: `$${getMaxSalary() / 1000}k` },
                                    ]}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body2">Total Ownership</Typography>
                                <Slider
                                    value={persistentState.settings.totalOwnership}
                                    onChange={(_, newValue) => handleInputChange('totalOwnership', newValue)}
                                    min={0}
                                    max={300}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    marks={[
                                        { value: 0, label: '0%' },
                                        { value: 150, label: '150%' },
                                        { value: 300, label: '300%' },
                                    ]}
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem' }}>Advanced Settings</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                {sport === 'NFL' ? (
                                    <TextField
                                        label="Defense vs Offense"
                                        type="number"
                                        value={persistentState.settings.hittersVsPitcher}
                                        onChange={(e) => handleInputChange('hittersVsPitcher', parseInt(e.target.value))}
                                        fullWidth
                                        size="small"
                                    />
                                ) : sport !== 'NBA' ? (
                                    <TextField
                                        label="Hitters vs Pitcher"
                                        type="number"
                                        value={persistentState.settings.hittersVsPitcher}
                                        onChange={(e) => handleInputChange('hittersVsPitcher', parseInt(e.target.value))}
                                        fullWidth
                                        size="small"
                                    />
                                ) : null}
                            </Grid>
                            {sport === 'NFL' && (
                                <>
                                    <Grid item xs={8}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={persistentState.settings.rbDstStack}
                                                    onChange={(e) => handleInputChange('rbDstStack', e.target.checked)}
                                                />
                                            }
                                            label="RB-DST Stack"
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle1">Flex Positions</Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={persistentState.settings.flexPositions.WR}
                                                    onChange={(e) => handleFlexPositionChange('WR', e.target.checked)}
                                                />
                                            }
                                            label="WR"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={persistentState.settings.flexPositions.RB}
                                                    onChange={(e) => handleFlexPositionChange('RB', e.target.checked)}
                                                />
                                            }
                                            label="RB"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={persistentState.settings.flexPositions.TE}
                                                    onChange={(e) => handleFlexPositionChange('TE', e.target.checked)}
                                                />
                                            }
                                            label="TE"
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={8}>
                                <TextField
                                    label="Max Players Per Team"
                                    type="number"
                                    value={persistentState.settings.maxPlayersPerTeam}
                                    onChange={(e) => handleInputChange('maxPlayersPerTeam', parseInt(e.target.value))}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={persistentState.settings.forceLowOwnedPlayers}
                                            onChange={(e) => handleInputChange('forceLowOwnedPlayers', e.target.checked)}
                                            size="small"
                                        />
                                    }
                                    label={<Typography variant="body2">Force Low Owned Player(s)</Typography>}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label="# of Low Owned"
                                    type="number"
                                    value={persistentState.settings.numberOfLowOwnedPlayers}
                                    onChange={(e) => handleInputChange('numberOfLowOwnedPlayers', parseInt(e.target.value))}
                                    disabled={!persistentState.settings.forceLowOwnedPlayers}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label="Low Own Threshold"
                                    type="number"
                                    value={persistentState.settings.lowOwnThreshold}
                                    onChange={(e) => handleInputChange('lowOwnThreshold', parseInt(e.target.value))}
                                    InputProps={{ endAdornment: '%' }}
                                    disabled={!persistentState.settings.forceLowOwnedPlayers}
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" gutterBottom>Distribution Method:</Typography>
                                <RadioGroup
                                    value={getSelectedDistributionMethod()}
                                    onChange={handleDistributionMethodChange}
                                >
                                    <FormControlLabel
                                        value="useRandomness"
                                        control={<Radio size="small" />}
                                        label={<Typography variant="body2">Use Randomness</Typography>}
                                    />
                                    <FormControlLabel
                                        value="usePlayerDistributions"
                                        control={<Radio size="small" />}
                                        label={<Typography variant="body2">Use Player Distributions</Typography>}
                                    />
                                    <Tooltip title={isFreeTier ? "Upgrade to access correlated distributions" : ""}>
                                        <FormControlLabel
                                            value="useCorrelatedDistributions"
                                            control={
                                                <Radio
                                                    size="small"
                                                    disabled={isFreeTier}
                                                    icon={isFreeTier ? <LockOutlined color="disabled" /> : undefined}
                                                    checkedIcon={isFreeTier ? <LockOutlined color="disabled" /> : undefined}
                                                />
                                            }
                                            label={
                                                <Typography variant="body2" color={isFreeTier ? 'text.disabled' : 'inherit'}>
                                                    Use Correlated Distributions
                                                </Typography>
                                            }
                                            sx={{ opacity: isFreeTier ? 1 : 1 }}
                                        />
                                    </Tooltip>
                                </RadioGroup>
                            </Grid>
                            {persistentState.settings.useRandomness && (
                                <Grid item xs={5}>
                                    <TextField
                                        label="Randomness %"
                                        type="number"
                                        value={persistentState.settings.randomnessPercentage}
                                        onChange={(e) => handleInputChange('randomnessPercentage', parseInt(e.target.value))}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </CardContent>
            </Card>

            <Card elevation={3} sx={{ mt: 1 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem' }}>Ownership Limits by Position</Typography>
                    {renderPositionLimits()}
                </CardContent>
            </Card>
        </Box>
    );
}

export default Settings;