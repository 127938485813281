import React, { createContext, useState, useContext } from 'react';
import { BaseLineup } from '../types';

interface LineupContextType {
    lineups: BaseLineup[];
    setLineups: React.Dispatch<React.SetStateAction<BaseLineup[]>>;
}

const LineupContext = createContext<LineupContextType | undefined>(undefined);

export const LineupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [lineups, setLineups] = useState<BaseLineup[]>([]);

    return (
        <LineupContext.Provider value={{ lineups, setLineups }}>
            {children}
        </LineupContext.Provider>
    );
};

export const useLineups = () => {
    const context = useContext(LineupContext);
    if (context === undefined) {
        throw new Error('useLineups must be used within a LineupProvider');
    }
    return context;
};