import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Checkbox, FormControlLabel, Link, InputAdornment } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface SignupFormProps {
    onSuccess: () => void;
}

const SignupForm: React.FC<SignupFormProps> = ({ onSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [marketingConsent, setMarketingConsent] = useState(false);
    const [errors, setErrors] = useState({ email: '', password: '', confirmPassword: '' });
    const [signupSuccess, setSignupSuccess] = useState(false);
    const { signup } = useAuth();
    const navigate = useNavigate();


    const validateForm = () => {
        let isValid = true;
        const newErrors = { email: '', password: '', confirmPassword: '' };

        if (!email) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
            isValid = false;
        }

        if (!password) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else if (password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
            isValid = false;
        }

        if (!confirmPassword) {
            newErrors.confirmPassword = 'Please confirm your password';
            isValid = false;
        } else if (password !== confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const userCredential = await signup(email, password);
                const user = userCredential.user;

                // Create user document in Firestore
                await setDoc(doc(db, 'users', user.uid), {
                    email: user.email,
                    subscriptionTier: 'free',
                    subscriptionEnd: null,
                });

                console.log('Signup successful');
                setSignupSuccess(true);

                if (marketingConsent) {
                    try {
                        const functions = getFunctions();
                        const getConvertKitApiKey = httpsCallable(functions, 'getConvertKitApiKey');
                        const result = await getConvertKitApiKey();

                        // Cast result.data as the expected object
                        const { apiKey } = result.data as { apiKey: string };

                        const response = await fetch('https://api.convertkit.com/v3/forms/7088074/subscribe', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                api_key: apiKey,
                                email: email,
                            }),
                        });

                        if (response.ok) {
                            console.log('Subscribed to marketing emails');
                        } else {
                            console.error('Failed to subscribe to marketing emails', await response.json());
                        }
                    } catch (error) {
                        console.error('Failed to subscribe to marketing emails', error);
                    }
                }
                onSuccess();
                navigate('/subscription');
            } catch (error) {
                console.error('Signup failed', error);
                // Handle the error (show an error message to the user)
            }
        }
    };

    if (signupSuccess) {
        return (
            <Box sx={{ textAlign: 'center', my: 2 }}>
                <Typography variant="h6" gutterBottom>Thanks for joining the movement!</Typography>

            </Box>
        );
    }

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={marketingConsent}
                        onChange={(e) => setMarketingConsent(e.target.checked)}
                        color="primary"
                    />
                }
                label={
                    <Typography variant="body2" color="text.secondary">
                        I agree to receive marketing emails. You can unsubscribe at any time.
                    </Typography>
                }
            />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                By signing up I agree to the{' '}
                <Link href="https://uncertainedge.com/terms/" target="_blank" rel="noopener" color="primary">
                    Terms of Service
                </Link>.
            </Typography>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, py: 1.5 }}
            >
                Sign Up
            </Button>
        </Box>
    );
};

export default SignupForm;