import React, { useEffect, useState } from 'react';
import { Box, Typography, Slider, Checkbox, FormControlLabel, Switch, Grid, Card, CardContent, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import { useSettings } from '../context/SettingsContext';
import { useSlateContext } from '../context/SlateContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { usePersistentState } from '../context/PersistentStateContext';

interface ShowdownSettingsProps {
    updatePersistentState: (updates: Partial<any>) => void;
    settings: any;
}

interface Game {
    id: string;
    awayTeamAbbreviation: string;
    homeTeamAbbreviation: string;
    startTime: string;
    slate_identifier: number;
}

function ShowdownSettings() {
    const { sport, slateId, site } = useSlateContext();
    const { persistentState, updatePersistentState } = usePersistentState();
    const [teams, setTeams] = useState<string[]>([]);


    useEffect(() => {
        const fetchGameData = async () => {
            if (slateId) {
                const gamesQuery = query(collection(db, 'slates_games'), where('slate_identifier', '==', slateId));
                const gameSnapshot = await getDocs(gamesQuery);
                const gameData = gameSnapshot.docs[0]?.data() as Game | undefined;

                if (gameData) {
                    setTeams([gameData.homeTeamAbbreviation, gameData.awayTeamAbbreviation]);
                }
            }
        };

        fetchGameData();
    }, [slateId]);

    useEffect(() => {
        if (teams.length === 2) {
            const allCombinations = generateAllCombinations(teams);
            const updatedCombinations = { ...persistentState.settings.showdownCombinations };

            allCombinations.forEach(combo => {
                if (updatedCombinations[combo.value] === undefined) {
                    updatedCombinations[combo.value] = true;
                }
            });

            // Only update if there are new combinations
            if (Object.keys(updatedCombinations).length !== Object.keys(persistentState.settings.showdownCombinations).length) {
                updatePersistentState({
                    settings: {
                        ...persistentState.settings,
                        showdownCombinations: updatedCombinations
                    }
                });
            }
        }
    }, [teams, persistentState.settings.showdownCombinations, updatePersistentState]);

    useEffect(() => {
        if (persistentState.settings.randomnessPercentage === 20) {
            updatePersistentState({
                settings: {
                    ...persistentState.settings,
                    randomnessPercentage: 50
                }
            });
        }
    }, []);

    const getMaxSalary = () => {
        return site === 'FanDuel' ? 60000 : 50000;
    };

    const handleInputChange = (field: string, value: any) => {
        updatePersistentState({
            settings: {
                ...persistentState.settings,
                [field]: value
            }
        });
    };

    const handleCombinationChange = (combination: string) => {
        const updatedCombinations = { ...persistentState.settings.showdownCombinations };

        if (combination.includes('3-3')) {
            // If it's a 3-3 combination, update both 3-3 combinations
            const otherCombination = combination.includes(teams[0])
                ? `${teams[1]}_3-${teams[0]}_3`
                : `${teams[0]}_3-${teams[1]}_3`;

            updatedCombinations[combination] = !updatedCombinations[combination];
            updatedCombinations[otherCombination] = updatedCombinations[combination];
        } else {
            updatedCombinations[combination] = !updatedCombinations[combination];
        }

        updatePersistentState({
            settings: {
                ...persistentState.settings,
                showdownCombinations: updatedCombinations
            }
        });
    };

    const handleDistributionMethodChange = (method: string) => {
        updatePersistentState({
            settings: {
                ...persistentState.settings,
                useRandomness: method === 'useRandomness',
                usePlayerDistributions: method === 'usePlayerDistributions',
                useCorrelatedDistributions: method === 'useCorrelatedDistributions',
            }
        });
    };

    const generateAllCombinations = (teams: string[]) => {
        if (site === 'FanDuel') {
            return [
                { text: `${teams[0]} 4 - ${teams[1]} 1`, value: `${teams[0]}_4-${teams[1]}_1` },
                { text: `${teams[0]} 3 - ${teams[1]} 2`, value: `${teams[0]}_3-${teams[1]}_2` },
                { text: `${teams[0]} 2 - ${teams[1]} 3`, value: `${teams[0]}_2-${teams[1]}_3` },
                { text: `${teams[0]} 1 - ${teams[1]} 4`, value: `${teams[0]}_1-${teams[1]}_4` },
                { text: `${teams[1]} 4 - ${teams[0]} 1`, value: `${teams[1]}_4-${teams[0]}_1` },
                { text: `${teams[1]} 3 - ${teams[0]} 2`, value: `${teams[1]}_3-${teams[0]}_2` },
                { text: `${teams[1]} 2 - ${teams[0]} 3`, value: `${teams[1]}_2-${teams[0]}_3` },
                { text: `${teams[1]} 1 - ${teams[0]} 4`, value: `${teams[1]}_1-${teams[0]}_4` },
            ];
        } else {
            return [
                { text: `${teams[0]} 5 - ${teams[1]} 1`, value: `${teams[0]}_5-${teams[1]}_1` },
                { text: `${teams[0]} 4 - ${teams[1]} 2`, value: `${teams[0]}_4-${teams[1]}_2` },
                { text: `${teams[0]} 3 - ${teams[1]} 3`, value: `${teams[0]}_3-${teams[1]}_3` },
                { text: `${teams[0]} 2 - ${teams[1]} 4`, value: `${teams[0]}_2-${teams[1]}_4` },
                { text: `${teams[0]} 1 - ${teams[1]} 5`, value: `${teams[0]}_1-${teams[1]}_5` },
                { text: `${teams[1]} 5 - ${teams[0]} 1`, value: `${teams[1]}_5-${teams[0]}_1` },
                { text: `${teams[1]} 4 - ${teams[0]} 2`, value: `${teams[1]}_4-${teams[0]}_2` },
                { text: `${teams[1]} 3 - ${teams[0]} 3`, value: `${teams[1]}_3-${teams[0]}_3` },
                { text: `${teams[1]} 2 - ${teams[0]} 4`, value: `${teams[1]}_2-${teams[0]}_4` },
                { text: `${teams[1]} 1 - ${teams[0]} 5`, value: `${teams[1]}_1-${teams[0]}_5` },
            ];
        }
    };

    const renderLineupCombinations = () => {
        if (teams.length !== 2) return null;

        const combinations = generateAllCombinations(teams);

        return (
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Combination</TableCell>
                            <TableCell align="center">Include</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {combinations.map((combo) => (
                            <TableRow key={combo.value}>
                                <TableCell>{combo.text}</TableCell>
                                <TableCell align="center">
                                    <Switch
                                        checked={persistentState.settings.showdownCombinations[combo.value] ?? true}
                                        onChange={() => handleCombinationChange(combo.value)}
                                        size="small"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Box sx={{ maxWidth: '100%', overflowX: 'auto', display: 'flex', flexDirection: 'row', p: 1 }}>
            <Card elevation={3} sx={{ flex: .8, mr: 2 }}>
                <CardContent sx={{ p: 3 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Typography variant="body1">Salary Range</Typography>
                            <Box sx={{ px: 2, pb: 2 }}>
                                <Slider
                                    value={persistentState.settings.salaryRange}
                                    onChange={(_, newValue) => handleInputChange('salaryRange', newValue)}
                                    valueLabelDisplay="auto"
                                    min={25000}
                                    max={getMaxSalary()}
                                    step={100}
                                    marks={[
                                        { value: 25000, label: '$25k' },
                                        { value: 35000, label: '$35k' },
                                        { value: 45000, label: '$45k' },
                                        { value: getMaxSalary(), label: `$${getMaxSalary() / 1000}k` },
                                    ]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="body1">Total Ownership</Typography>
                            <Box sx={{ px: 2, pb: 2 }}>
                                <Slider
                                    value={persistentState.settings.totalOwnership}
                                    onChange={(_, newValue) => handleInputChange('totalOwnership', newValue)}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={300}
                                    step={1}
                                    marks={[
                                        { value: 0, label: '0%' },
                                        { value: 150, label: '150%' },
                                        { value: 300, label: '300%' },
                                    ]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={persistentState.settings.forceLowOwnedPlayers}
                                        onChange={(e) => handleInputChange('forceLowOwnedPlayers', e.target.checked)}
                                    />
                                }
                                label="Force Low Owned Player(s)"
                            />
                            <Tooltip title="Enter the # of Low Owned Players that each lineup must have" arrow>
                                <TextField
                                    label="# of Low Owned"
                                    type="number"
                                    value={persistentState.settings.numberOfLowOwnedPlayers}
                                    onChange={(e) => handleInputChange('numberOfLowOwnedPlayers', parseInt(e.target.value))}
                                    disabled={!persistentState.settings.forceLowOwnedPlayers}
                                    size="small"
                                    sx={{ ml: 2, width: 100 }}
                                />
                            </Tooltip>
                            <Tooltip title="Enter the percentage threshold for what defines low ownership players" arrow>
                                <TextField
                                    label="Low Own Threshold (%)"
                                    type="number"
                                    value={persistentState.settings.lowOwnThreshold}
                                    onChange={(e) => handleInputChange('lowOwnThreshold', parseInt(e.target.value))}
                                    disabled={!persistentState.settings.forceLowOwnedPlayers}
                                    size="small"
                                    sx={{ ml: 2, width: 100 }}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label={sport === 'NFL' ? "Defense vs Offense" : "Hitters vs Pitcher"}
                                type="number"
                                value={persistentState.settings.hittersVsPitcher}
                                onChange={(e) => handleInputChange('hittersVsPitcher', parseInt(e.target.value))}
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>Distribution Method</Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={persistentState.settings.useRandomness}
                                        onChange={() => handleDistributionMethodChange('useRandomness')}
                                    />
                                }
                                label="Use Randomness"
                            />
                            <TextField
                                label="Randomness %"
                                type="number"
                                value={persistentState.settings.randomnessPercentage}
                                onChange={(e) => handleInputChange('randomnessPercentage', Number(e.target.value))}
                                disabled={!persistentState.settings.useRandomness}
                                inputProps={{ min: 0, max: 100 }}
                                size="small"
                                sx={{ ml: 2, mr: 2, width: 100 }}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={persistentState.settings.usePlayerDistributions}
                                        onChange={() => handleDistributionMethodChange('usePlayerDistributions')}
                                    />
                                }
                                label="Use Player Distributions"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={persistentState.settings.useCorrelatedDistributions}
                                        onChange={() => handleDistributionMethodChange('useCorrelatedDistributions')}
                                    />
                                }
                                label="Use Correlated Distributions"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card elevation={3} >
                <CardContent>
                    <Typography variant="h4" gutterBottom>Lineup Combinations</Typography>
                    {renderLineupCombinations()}
                </CardContent>
            </Card>
        </Box>
    );
}

export default ShowdownSettings;